<template>
  <div class="flex flex-col min-h-screen bg-gray-100">
    <header class="flex flex-col w-full border-b">
      <Navbar :activeTab="0" />
    </header>
    <div class="flex flex-col gap-6 mb-16 p-6">
      <div
        v-if="isLoadingAccountBalance"
        class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      >
        <!-- Spinner -->
        <div
          class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
        ></div>
      </div>
      <div class="flex flex-col items-start">
        <span class="text-black text-3xl font-semibold leading-9">Billing</span>
      </div>
      <!-- available balance  -->
      <div class="flex flex-col gap-6" v-if="showCurrentAccountBalance">
        <CurrentAccountBalance
          :availableBalance="availableBalance"
          :overdrawnBalance="overdrawnBalance"
          :pendingBalance="pendingBalance"
          :autoTopup="autoTopup"
          :autoRechargeAmount="autoRechargeAmount"
          :lowBalanceAmount="lowBalanceAmount"
          :paymentMethodCardNumber="paymentMethodCardNumber"
          :paymentMethodCreatedAt="paymentMethodCreatedAt"
          :paymentMethodSource="paymentMethodSource"
          :paymentMethodInvoiceRecipientEmail="
            paymentMethodInvoiceRecipientEmail || ''
          "
          :updateAccountBalance="updateAccountBalance"
        />
        <InvoiceHistory />
        <CardTransactionHistory :cardTransactions="cardTransactions" />
      </div>
      <UpdateAccountBalance
        v-else
        :accountBalanceId="accountBalanceId"
        :availableBalance="availableBalance"
        :autoTopup="autoTopup"
        :autoRechargeAmount="autoRechargeAmount"
        :lowBalanceAmount="lowBalanceAmount"
        :paymentMethodExpirationDate="paymentMethodExpirationDate"
        :paymentMethodCardNumber="paymentMethodCardNumber"
        :paymentMethodInvoiceRecipientEmail="paymentMethodInvoiceRecipientEmail"
        :paymentMethodSource="paymentMethodSource"
        :paymentMethodId="paymentMethodId"
        :cancel="cancelUpdateAccountBalance"
        @refetchAccountBalance="refetchAccountBalance"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import CardTransactionHistory from "@/components/billing/card/CardTransactionHistory.vue";
import {
  AccountBalance,
  ActivePaymentMethod,
  CardTransaction,
} from "@/types/dashboard-types";
import CurrentAccountBalance from "@/components/billing/CurrentAccountBalance.vue";
import UpdateAccountBalance from "@/components/billing/UpdateAccountBalance.vue";
import InvoiceHistory from "@/components/billing/invoice/InvoiceHistory.vue";
import Navbar from "@/components/dashboard/Navbar.vue";

const GET_ACCOUNT_BALANCE = gql`
  query {
    fetchAccountBalance {
      id
      availableBalance
      overdrawnBalance
      pendingBalance
      autoTopup
      autoRechargeAmount
      lowBalanceAmount
      cardTransactions {
        id
        amount
        status
        createdAt
        cardCharged {
          lastFour
          expMonth
          expYear
        }
      }
      activePaymentMethod {
        paymentMethodId
        source
        isActive
        invoiceRecipientEmail
        createdAt
        paymentInfo {
          cardNumber
          expirationDate
        }
      }
    }
  }
`;

export default defineComponent({
  name: "BillingContainer",
  components: {
    CurrentAccountBalance,
    UpdateAccountBalance,
    InvoiceHistory,
    CardTransactionHistory,
    Navbar,
  },
  setup() {
    const availableBalance = ref(0);
    const overdrawnBalance = ref(0.0);
    const pendingBalance = ref(0);
    const autoTopup = ref(false);
    const autoRechargeAmount = ref(0);
    const lowBalanceAmount = ref(0);
    const paymentMethodSource = ref("");
    const paymentMethodCreatedAt = ref("");
    const paymentMethodCardNumber = ref("");
    const paymentMethodExpirationDate = ref("");
    const paymentMethodInvoiceRecipientEmail = ref("");
    const cardTransactions = ref<CardTransaction[]>([]);
    const showCurrentAccountBalance = ref(true);
    const paymentMethodId = ref("");
    const accountBalanceId = ref("");
    const isLoadingAccountBalance = ref(false);

    onMounted(() => {
      getAccountBalance();
    });

    const getAccountBalance = () => {
      const {
        result: queryResult,
        error: queryError,
        refetch: refetchAccountBalance,
      } = useQuery(GET_ACCOUNT_BALANCE);

      refetchAccountBalance();

      isLoadingAccountBalance.value = true;

      watch(
        () => queryResult.value,
        (newValue) => {
          if (newValue) {
            isLoadingAccountBalance.value = false;
          }
          if (newValue?.fetchAccountBalance) {
            handleAccountBalance(newValue.fetchAccountBalance);
          }
        },
        { immediate: true }
      );

      watch(
        () => queryError.value,
        (newError) => {
          if (newError) {
            console.error("Query error: ", newError);
            isLoadingAccountBalance.value = false;
          }
        },
        { immediate: true }
      );
    };

    const handleAccountBalance = (accountBalance: AccountBalance) => {
      accountBalanceId.value = accountBalance.id;
      cardTransactions.value = accountBalance.cardTransactions;

      updateBalances(accountBalance);
      updateAutoTopup(accountBalance);
      if (accountBalance.activePaymentMethod.isActive) {
        updatePaymentMethod(accountBalance.activePaymentMethod);
      }
    };

    const updateBalances = (accountBalance: AccountBalance) => {
      availableBalance.value = accountBalance.availableBalance;
      pendingBalance.value = accountBalance.pendingBalance;
      overdrawnBalance.value = accountBalance.overdrawnBalance;
    };

    const updateAutoTopup = (accountBalance: AccountBalance) => {
      autoTopup.value = accountBalance.autoTopup;
      autoRechargeAmount.value = accountBalance.autoRechargeAmount;
      lowBalanceAmount.value = accountBalance.lowBalanceAmount;
    };

    const updatePaymentMethod = (activePaymentMethod: ActivePaymentMethod) => {
      paymentMethodSource.value = activePaymentMethod.source;
      if (activePaymentMethod.source === "credit_card") {
        paymentMethodCardNumber.value =
          activePaymentMethod.paymentInfo.cardNumber;
        paymentMethodExpirationDate.value =
          activePaymentMethod.paymentInfo.expirationDate;
      }
      paymentMethodCreatedAt.value = formatDateToLongDateString(
        activePaymentMethod.createdAt
      );
      paymentMethodInvoiceRecipientEmail.value =
        activePaymentMethod.invoiceRecipientEmail;
      paymentMethodId.value = activePaymentMethod.paymentMethodId;
    };

    const formatDateToLongDateString = (dateString: Date): string => {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const updateAccountBalance = () => {
      console.log("Update Account Balance clicked");
      showCurrentAccountBalance.value = false;
    };

    const cancelUpdateAccountBalance = () => {
      showCurrentAccountBalance.value = true;
    };

    const refetchAccountBalance = () => {
      getAccountBalance();
      showCurrentAccountBalance.value = true;
    };

    return {
      availableBalance,
      overdrawnBalance,
      pendingBalance,
      autoTopup,
      autoRechargeAmount,
      lowBalanceAmount,
      paymentMethodSource,
      paymentMethodCreatedAt,
      paymentMethodCardNumber,
      paymentMethodInvoiceRecipientEmail,
      paymentMethodExpirationDate,
      updateAccountBalance,
      showCurrentAccountBalance,
      cancelUpdateAccountBalance,
      paymentMethodId,
      accountBalanceId,
      refetchAccountBalance,
      isLoadingAccountBalance,
      cardTransactions,
    };
  },
});
</script>

<template>
  <div
    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
  >
    <!-- Spinner -->
    <div
      class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
    >
      <span class="text-white text-3xl font-semibold leading-9">
        Signing you in...
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";

const GET_COMPANY_USER = gql`
  query getCompanyUser {
    getCompanyUser {
      id
      email
      firstName
      lastName
      profilePicture
      company {
        name
        website
      }
      uuid
    }
  }
`;

export default defineComponent({
  name: "SignInLanding",
  setup() {
    const authToken = ref<string | null>(null);
    const router = useRouter();
    const userStore = useUserStore();

    onMounted(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      if (token) {
        userStore.clearStore();
        userStore.setToken(token);
        if (userStore.token) {
          fetchCompanyUser();
        }

        router.replace({ path: router.currentRoute.value.path, query: {} });
      } else {
        handleError("No token found.");
      }
    });

    const produktlyIdentifyUserLogin = (uuid: string, email: string) => {
      if (window.Produktly) {
        window.Produktly.identifyUser(uuid, {
          email: email,
        });
      }
    };

    const fetchCompanyUser = async () => {
      const { result: companyUserResult, error: companyUserError } =
        useQuery(GET_COMPANY_USER);

      watch(
        () => companyUserResult.value,
        (newValue) => {
          if (newValue) {
            const companyUser = newValue.getCompanyUser;
            produktlyIdentifyUserLogin(companyUser.uuid, companyUser.email);
            userStore.setEmail(companyUser.email);
            userStore.setFirstName(companyUser.firstName);
            userStore.setLastName(companyUser.lastName);
            userStore.setCompanyName(companyUser.company.name);
            userStore.setCompanyWebsite(companyUser.company.website);
            userStore.setProfilePicture(companyUser.profilePicture);
            router.push({ name: "ExpertPrograms" });
          } else {
            handleError("No company user found");
          }
        }
      );

      watch(
        () => companyUserError.value,
        (newError) => {
          if (newError) {
            handleError(newError.message);
          }
        }
      );
    };

    const handleError = (error: string) => {
      const landingUrl = `${
        process.env.VUE_APP_SIGN_IN_FORM_URL
      }?error=${encodeURIComponent(error)}`;
      window.open(landingUrl, "_self");
    };

    return {
      authToken,
    };
  },
});
</script>

<template>
  <div>My Custom App Where I Will Show the Experts Directory</div>
  <iframe
    src="https://test-manage.slashexperts.com/experts_directory?company_id=677a77b8538ef93671f295fe&program=59ebb15c-8bfd-4fe9-8367-56c865e8821e"
    width="100%"
    height="600px"
    frameborder="0"
  >
  </iframe>
</template>

import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/shared/linkedin-icon.svg'


const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "flex flex-col gap-2 items-end" }
const _hoisted_3 = { class: "flex flex-row gap-2 items-center" }
const _hoisted_4 = { class: "flex flex-row gap-1 space-x-2" }
const _hoisted_5 = { class: "flex flex-row gap-2 items-center" }
const _hoisted_6 = { class: "flex flex-row gap-1 space-x-2" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(["flex flex-col grow shrink p-2 bg-white rounded-lg min-w-[10.5rem] max-w-[21rem] sm:min-w-[21rem] overflow-hidden", {
      'items-center justify-center text-center': _ctx.isCentered,
      'items-start text-left': !_ctx.isCentered,
    }]),
    style: _normalizeStyle({
      fontFamily: _ctx.fontFamily,
      fontSize: _ctx.fontSize + 'px',
      height: !_ctx.isGridUneven ? _ctx.computedHeight : 'auto',
    })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex gap-2.5", {
        'items-start': !_ctx.isCentered,
        'justify-center items-center': _ctx.isCentered,
        [_ctx.profileImageAndNameLayoutClass]: true,
      }])
    }, [
      _createElementVNode("img", {
        src: _ctx.profile.imageUrl,
        class: _normalizeClass([_ctx.profileImageClass, "object-cover shrink-0 w-16 h-16"]),
        alt: ""
      }, null, 10, _hoisted_1),
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col", {
          'items-start': !_ctx.isCentered,
          'justify-center items-center': _ctx.isCentered,
        }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["flex flex-row gap-4 items-center", {
            'justify-center': _ctx.isCentered,
          }])
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["text-xs font-semibold leading-loose text-zinc-800", { 'text-center': _ctx.isCentered }]),
            style: _normalizeStyle({
              fontSize: _ctx.fontSize + 'px',
              fontWeight: _ctx.boldness,
            })
          }, _toDisplayString(_ctx.expertName(_ctx.profile)), 7),
          _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            class: "object-cover shrink-0 w-6 h-6",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleLinkedinClick(_ctx.profile.linkedinUrl)))
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["flex flex-col items-center w-full max-w-full", {
            'justify-center': _ctx.isCentered,
          }])
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["text-xs font-semibold text-zinc-800 break-words overflow-hidden whitespace-normal w-full max-h-[45px] leading-[1.2] line-clamp-2", { 'text-center': _ctx.isCentered }]),
            style: _normalizeStyle({
              fontSize: _ctx.fontSize + 'px',
              fontWeight: _ctx.boldness,
            })
          }, _toDisplayString(_ctx.expertTitleAndCompanyName(_ctx.profile)), 7)
        ], 2)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-col justify-end w-full gap-3 mt-2", {
        'items-center': _ctx.isCentered,
        'items-start': !_ctx.isCentered,
        'mt-auto': !_ctx.isGridUneven,
      }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "font-bold" }, "Expertise", -1)),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
              return _createElementVNode("div", {
                key: index,
                class: "w-7 h-2",
                style: _normalizeStyle({
                backgroundColor:
                  index <= _ctx.profile.userProgramProfile[0].expertise
                    ? _ctx.backgroundColor
                    : '#D1D5DB',
              })
              }, null, 4)
            }), 64))
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-bold" }, "Experience", -1)),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
              return _createElementVNode("div", {
                key: index,
                class: "w-7 h-2",
                style: _normalizeStyle({
                backgroundColor:
                  index <= _ctx.profile.userProgramProfile[0].experience
                    ? _ctx.backgroundColor
                    : '#D1D5DB',
              })
              }, null, 4)
            }), 64))
          ])
        ])
      ]),
      (_ctx.profile.country)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("span", {
              class: "overflow-hidden gap-1.5 self-stretch px-1.5 py-0.5 my-auto whitespace-nowrap rounded-md bg-slate-50",
              style: _normalizeStyle({
            fontWeight: _ctx.boldness,
          })
            }, _toDisplayString(_ctx.profile.country), 5)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["w-full", { 'flex justify-center': _ctx.isCentered }])
      }, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleBookMeeting(_ctx.profile.uuid))),
          class: "overflow-hidden gap-2 w-full py-1.5 pr-2.5 pl-2.5 mt-2.5 font-medium rounded shadow-sm",
          disabled: !_ctx.enableBookMeeting,
          style: _normalizeStyle({
            backgroundColor: _ctx.backgroundColor,
            color: _ctx.textColor,
          })
        }, " Book A Meeting ", 12, _hoisted_8)
      ], 2)
    ], 2)
  ], 6))
}
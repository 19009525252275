<template>
  <div
    class="flex flex-col gap-4 border border-gray-200 rounded-lg p-4 overflow-y-auto max-h-[300px]"
  >
    <div class="flex flex-col gap-2 items-start">
      <span class="text-gray-800 text-base font-semibold leading-6">
        Card transaction history ({{ cardTransactions.length }})
      </span>
    </div>

    <div class="overflow-x-auto w-full">
      <table class="min-w-full border border-gray-300">
        <thead class="bg-gray-150">
          <tr>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              No.
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Amount
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Card Used
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Created At
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in cardTransactions"
            :key="index"
            class="hover:bg-gray-50 text-left"
          >
            <td
              class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
            >
              {{ index + 1 }}
            </td>
            <td
              class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
            >
              ${{ row.amount.toFixed(2) }}
            </td>
            <td class="px-4 py-2 font-medium text-xs leading-4 border-b">
              <div class="flex flex-col gap-1" v-if="row?.cardCharged">
                <span>**** **** **** {{ row?.cardCharged?.lastFour }}</span>
                <span
                  >Expiry: {{ row?.cardCharged?.expMonth }}/{{
                    row?.cardCharged?.expYear
                  }}</span
                >
              </div>
            </td>
            <td
              class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
            >
              {{ formatDateToLongDateString(row.createdAt) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { CardTransaction } from "@/types/dashboard-types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "CardTransactionHistory",
  props: {
    cardTransactions: {
      type: Array as PropType<CardTransaction[]>,
      required: true,
    },
  },
  setup() {
    const formatDateToLongDateString = (dateString: Date): string => {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    return {
      formatDateToLongDateString,
    };
  },
});
</script>

import { Ref } from "vue";

export const checkUrlProtocol = (reactiveState: Ref<string>): string => {
  if (
    reactiveState.value &&
    !reactiveState.value.startsWith("http://") &&
    !reactiveState.value.startsWith("https://")
  ) {
    reactiveState.value = `https://${reactiveState.value}`;
  }
  return reactiveState.value;
};

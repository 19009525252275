<template>
  <div class="flex flex-col min-h-screen bg-gray-100">
    <header class="flex flex-col w-full border-b">
      <Navbar :activeTab="0" />
    </header>
    <div class="flex flex-col gap-6 mb-16 p-6">
      <div class="flex flex-col ml-6 gap-6 mb-4">
        <div class="flex flex-col items-start">
          <span class="text-black text-3xl font-semibold leading-9 text-left"
            >Team Management</span
          >
        </div>
        <!-- total member + add members -->
        <div class="flex flex-wrap justify-between items-center">
          <div class="flex flex-wrap gap-2 items-center">
            <span class="text-gray-800 text-base font-semibold leading-6"
              >Team Members</span
            ><span class="text-gray-500 text-sm font-normal leading-5"
              >({{ teamMembersCount }})</span
            >
          </div>
          <div class="flex flex-col">
            <BaseButton
              type="button"
              class="px-4 py-2 rounded-lg text-white text-sm font-medium leading-5 bg-primary-purple"
              @click="handleAddTeamMember"
            >
              Add member +
            </BaseButton>
          </div>
        </div>
        <div class="flex flex-col">
          <transition name="fade">
            <span
              v-if="sentSuccessfully"
              class="text-primary-purple text-sm font-medium leading-5 text-left"
            >
              Invites sent successfully
            </span>
          </transition>
        </div>

        <!-- members list -->
        <TeamMembersList
          :newInvitesSent="newInvitesSent"
          @teamMembersCount="teamMembersCount = $event"
        />
      </div>
      <AddTeamMemberModal
        v-if="isAddMemberModalOpen"
        :isVisible="isAddMemberModalOpen"
        invitationType="company"
        @update:isVisible="closeInvitationModal"
        @update:emailsSent="handleEmailsSent"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import TeamMembersList from "@/components/team/TeamMembersList.vue";
import AddTeamMemberModal from "@/components/team/AddTeamMemberModal.vue";
import Navbar from "@/components/dashboard/Navbar.vue";

export default defineComponent({
  name: "TeamManagementContainer",
  components: {
    BaseButton,
    TeamMembersList,
    AddTeamMemberModal,
    Navbar,
  },
  setup() {
    const isAddMemberModalOpen = ref(false);
    const newInvitesSent = ref(false);
    const sentSuccessfully = ref(false);
    const teamMembersCount = ref(0);

    const handleAddTeamMember = () => {
      isAddMemberModalOpen.value = true;
    };

    const handleEmailsSent = () => {
      isAddMemberModalOpen.value = false;
      newInvitesSent.value = !newInvitesSent.value;
      sentSuccessfully.value = true;
      setTimeout(() => {
        sentSuccessfully.value = false;
      }, 2000);
    };

    const closeInvitationModal = () => {
      isAddMemberModalOpen.value = false;
      newInvitesSent.value = !newInvitesSent.value;
    };

    return {
      isAddMemberModalOpen,
      handleAddTeamMember,
      newInvitesSent,
      handleEmailsSent,
      sentSuccessfully,
      closeInvitationModal,
      teamMembersCount,
    };
  },
});
</script>

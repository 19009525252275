<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    @click.self="closeModal"
  >
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative">
      <button
        class="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        @click="closeModal"
      >
        ✕
      </button>
      <div class="flex flex-col">
        <div class="flex flex-col mt-8 gap-4 max-w-full mx-10">
          <div
            class="flex justify-start items-start border-b border-gray-200 self-start"
          >
            <div
              class="flex flex-col justify-center self-stretch my-auto text-sm font-medium leading-none text-violet-600 whitespace-nowrap"
            >
              <div
                class="flex gap-2 justify-center items-center self-center px-1 pb-4"
              >
                <div v-html="HTML_ICON" class="text-primary-purple"></div>
                <span class="self-stretch my-auto">HTML</span>
              </div>
              <div class="flex w-full bg-violet-600 min-h-[2px]"></div>
            </div>
          </div>
          <div class="flex flex-col w-full max-md:ml-0 max-md:w-full">
            <div
              class="flex flex-col grow mt-3 text-sm font-medium leading-5 text-black max-md:mt-7 max-md:max-w-full"
            >
              <pre
                ref="codeSnippet"
                class="self-start text-left overflow-x-auto p-4 rounded-md max-w-full break-all max-md:overflow-x-scroll"
              >
            <code class="language-html break-all max-w-full" id="code">
&lt;iframe 
  src=&quot;{{ url() }}&quot; 
  width=&quot;100%&quot; 
  height=&quot;600px&quot; 
  frameborder=&quot;0&quot;&gt;
&lt;/iframe&gt;
            </code>
          </pre>
            </div>
          </div>
          <div class="flex flex-col ml-5 w-full max-md:ml-0 max-md:w-full">
            <BaseButton
              @click="copyCodeToClipboard"
              class="overflow-hidden gap-3 self-stretch py-2.5 pr-4 pl-4 w-full text-base font-medium text-indigo-700 bg-indigo-50 rounded-md shadow-sm max-md:mt-4"
            >
              {{ buttonText }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { HTML_ICON } from "@/assets/svg/shared/svg-constants";

export default defineComponent({
  name: "DirectoryCodeModal",
  components: {
    BaseButton,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    companyId: {
      type: String,
      required: true,
    },
    programUuid: {
      type: String,
      required: true,
    },
  },

  emits: ["closeCodeModal"],
  setup(props, { emit }) {
    const codeSnippet = ref<HTMLElement | null>(null);
    const buttonText = ref("Copy Code");

    const copyCodeToClipboard = () => {
      const codeElement = codeSnippet.value?.querySelector("code");
      const codeText = codeElement?.textContent || "";
      if (codeText) {
        navigator.clipboard
          .writeText(codeText)
          .then(() => {
            buttonText.value = "Copied!";
            setTimeout(() => {
              buttonText.value = "Copy Code";
            }, 2000);
          })
          .catch((error) => {
            console.error("Failed to copy the code: ", error);
          });
      } else {
        console.error("Code element not found");
      }
    };

    const url = () => {
      return `${process.env.VUE_APP_BASE_URL}experts_directory?company_id=${props.companyId}&program=${props.programUuid}`;
    };

    const closeModal = () => {
      emit("closeCodeModal");
    };

    return {
      closeModal,
      copyCodeToClipboard,
      buttonText,
      url,
      HTML_ICON,
      codeSnippet,
    };
  },
});
</script>

<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 flex items-center justify-center z-50"
  >
    <div class="fixed inset-0 bg-black opacity-50"></div>
    <div
      id="edit-program-modal"
      class="flex flex-col bg-white p-6 max-h-[80vh] overflow-y-auto rounded-lg gap-2 shadow-lg z-10 max-w-md w-full mx-4"
    >
      <!-- title -->
      <div class="flex flex-col">
        <div class="flex flex-wrap justify-between items-center">
          <div>
            <span class="text-2xl font-medium leading-8 text-gray-800"
              >Expert Program Details</span
            >
          </div>
          <div class="text-right">
            <BaseButton
              @click="closeModal"
              class="text-lg text-gray-500 hover:text-black"
            >
              X
            </BaseButton>
          </div>
        </div>
      </div>

      <div class="flex justify-center mb-4">
        <div
          v-if="errorMessage"
          role="alert"
          class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          style="max-height: 80px; overflow-y: auto"
        >
          <!-- Icon Container -->
          <div
            class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
          >
            <!-- Custom Image Icon -->
            <img
              src="../../../../assets/shared/failure.svg"
              alt="Error Icon"
              class="w-4 h-4"
            />
          </div>
          <p>{{ errorMessage }}</p>
        </div>
      </div>

      <!-- about area -->
      <div class="flex flex-col gap-2 text-left">
        <BaseLabel
          forAttr="aboutProgram"
          text="About Program"
          class="text-sm font-medium leading-5 text-gray-800"
        />
        <textarea
          v-model="localAboutProgram"
          @input="handleAboutProgramChange($event)"
          placeholder="Type your text here..."
          class="w-full border rounded-md p-2 resize-none focus:outline-none"
          :class="{
            'border-red-500': aboutProgramWarning,
            'border-gray-300': !aboutProgramWarning,
          }"
          rows="4"
        ></textarea>
        <span
          class="text-xs mt-1 text-gray-400"
          :class="{ 'text-red-500': aboutProgramWarning }"
        >
          {{ localAboutProgram.length }} / 1056
        </span>
      </div>

      <!-- payout -->
      <div class="flex flex-col gap-2 mt-4 text-left">
        <BaseLabel
          forAttr="payout"
          text="Expert Payout"
          class="text-sm font-medium leading-5 text-gray-800"
        />
        <CurrencyInput
          v-model="localPayout"
          leadingText="$"
          trailingText="USD"
          placeholder="100"
        />
      </div>

      <!-- meeting duration less than 5 minutes -->
      <div class="relative flex flex-col mt-6 text-zinc-800 gap-2">
        <BaseLabel
          forAttr="meetingDuration"
          text="Payout only if meeting duration is more than 5 minutes."
          class="font-medium text-left"
        />
        <div class="flex flex-col justify-center">
          <BaseButton
            type="button"
            class="flex items-center w-12 h-6 rounded-xl transition-colors duration-300"
            :class="{
              'bg-violet-600': localpayoutOnlyCallDurationMoreThan5,
              'bg-gray-200': !localpayoutOnlyCallDurationMoreThan5,
            }"
            @click="togglepayoutOnlyCallDurationMoreThan5()"
            aria-label="Toggle button"
          >
            <span
              class="flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform"
              :class="{
                'translate-x-6': localpayoutOnlyCallDurationMoreThan5,
                'translate-x-0.5': !localpayoutOnlyCallDurationMoreThan5,
              }"
            ></span>
          </BaseButton>
        </div>
      </div>

      <!-- program goals section -->
      <div
        class="relative flex flex-col justify-start mt-6 text-base font-medium leading-6 z-10"
      >
        <BaseLabel
          forAttr="Goals"
          text="Goals & Use Cases"
          class="text-lg font-medium leading-5 text-zinc-800 text-left"
        />
        <GoalsCheckboxList
          v-model:convertWebsiteVisitors="localConvertWebsiteVisitors"
          v-model:generateIntentSignals="localGenerateIntentSignals"
          v-model:enableSales="localEnableSales"
          v-model:engageNewCustomer="localEngageNewCustomer"
          v-model:driveConversion="localDriveConversion"
          v-model:sdrSequenceStep="localSdrSequenceStep"
          v-model:lateStageCustomerReferences="localLateStageCustomerReferences"
        />
      </div>

      <div class="flex flex-wrap justify-end gap-2 mt-2">
        <BaseButton
          type="button"
          @click="closeModal"
          :disabled="updateProgramLoading"
          class="text-black border border-gray-300 px-4 py-2 rounded"
        >
          Close
        </BaseButton>
        <BaseButton
          type="button"
          @click="handleUpdate"
          :disabled="updateProgramLoading"
          class="bg-primary-purple text-white px-4 py-2 rounded"
        >
          <span v-if="!updateProgramLoading">Update</span>
          <span v-else>Updating...</span>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import GoalsCheckboxList from "@/components/expert-program/program-details/GoalsCheckboxList.vue";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

const UPDATE_PROGRAM = gql`
  mutation updateProgram($input: UpdateProgramInput!) {
    updateProgram(input: $input) {
      program {
        id
      }
      errors
    }
  }
`;

export default defineComponent({
  name: "CenteredModal",
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    aboutProgram: {
      type: String,
      required: true,
    },
    payout: {
      type: Number,
      required: true,
    },
    payoutOnlyCallDurationMoreThan5: {
      type: Boolean,
      required: true,
    },
    convertWebsiteVisitors: {
      type: Boolean,
      required: true,
    },
    generateIntentSignals: {
      type: Boolean,
      required: true,
    },
    enableSales: {
      type: Boolean,
      required: true,
    },
    engageNewCustomer: {
      type: Boolean,
      required: true,
    },
    driveConversion: {
      type: Boolean,
      required: true,
    },
    sdrSequenceStep: {
      type: Boolean,
      required: true,
    },
    lateStageCustomerReferences: {
      type: Boolean,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseButton,
    BaseLabel,
    GoalsCheckboxList,
    CurrencyInput,
  },
  emits: ["close", "programUpdated"],
  setup(props, { emit }) {
    const aboutProgramWarning = ref(false);
    const errorMessage = ref("");
    const localAboutProgram = ref(props.aboutProgram);
    const localPayout = ref(props.payout / 100);
    const localpayoutOnlyCallDurationMoreThan5 = ref(
      props.payoutOnlyCallDurationMoreThan5
    );
    const localConvertWebsiteVisitors = ref(props.convertWebsiteVisitors);
    const localGenerateIntentSignals = ref(props.generateIntentSignals);
    const localEnableSales = ref(props.enableSales);
    const localEngageNewCustomer = ref(props.engageNewCustomer);
    const localDriveConversion = ref(props.driveConversion);
    const localSdrSequenceStep = ref(props.sdrSequenceStep);
    const localLateStageCustomerReferences = ref(
      props.lateStageCustomerReferences
    );

    const {
      mutate: updateProgram,
      onDone: updateProgramDone,
      onError: updateProgramError,
      loading: updateProgramLoading,
    } = useMutation(UPDATE_PROGRAM);

    const handleAboutProgramChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.value.length > 1056) {
        alertUser(aboutProgramWarning);
      }
      localAboutProgram.value = target.value.slice(0, 1056);
      // emit("headerTextChanged", topHeaderText.value);
    };

    const alertUser = (warningState: Ref<boolean>) => {
      warningState.value = true;

      setTimeout(() => {
        warningState.value = false;
      }, 1000);
    };

    const inputValid = () => {
      if (!localPayout.value) {
        handleError("Please provide a value for payout");
        return false;
      }

      if (localPayout.value < 1) {
        handleError("Payout must be at least $1");
        return false;
      }
      return true;
    };

    const handleUpdate = () => {
      if (inputValid()) {
        callUpdateProgram();
      }
    };

    const callUpdateProgram = () => {
      updateProgram({
        input: {
          programParams: {
            id: props.programId,
            payout: localPayout.value,
            description: localAboutProgram.value,
            payoutOnlyCallDurationMoreThan5:
              localpayoutOnlyCallDurationMoreThan5.value,
            convertWebsiteVisitors: localConvertWebsiteVisitors.value,
            generateIntentSignals: localGenerateIntentSignals.value,
            enableSales: localEnableSales.value,
            driveConversion: localDriveConversion.value,
            lateStageCustomerReferences: localLateStageCustomerReferences.value,
            sdrSequenceStep: localSdrSequenceStep.value,
            engageNewCustomer: localEngageNewCustomer.value,
          },
        },
      });
      updateProgramDone((response) => {
        if (response) {
          if (response.data.updateProgram.errors.length > 0) {
            handleError(response.data.updateProgram.errors.join("."));
          } else {
            emit("programUpdated");
          }
        }
      });
      updateProgramError((error: ApolloError) => {
        handleError(error.message);
        console.error("Submission failed:", error.message);
      });
    };

    const scrollToTop = () => {
      const element = document.getElementById("edit-program-modal");
      if (!element) return;

      const scrollTop = element.scrollTop;
      if (scrollTop > 0) {
        window.requestAnimationFrame(scrollToTop);
        element.scrollTop = scrollTop - scrollTop / 8;
      }
    };

    const handleError = (message: string) => {
      errorMessage.value = message;
      scrollToTop();

      setTimeout(() => {
        errorMessage.value = "";
      }, 2500);
    };

    const closeModal = () => {
      if (!updateProgramLoading.value) {
        emit("close");
      }
    };

    const togglepayoutOnlyCallDurationMoreThan5 = () => {
      localpayoutOnlyCallDurationMoreThan5.value =
        !localpayoutOnlyCallDurationMoreThan5.value;
    };

    return {
      closeModal,
      handleUpdate,
      localAboutProgram,
      localPayout,
      handleAboutProgramChange,
      aboutProgramWarning,
      localConvertWebsiteVisitors,
      localGenerateIntentSignals,
      localEnableSales,
      localEngageNewCustomer,
      localDriveConversion,
      localSdrSequenceStep,
      localLateStageCustomerReferences,
      updateProgramLoading,
      errorMessage,
      localpayoutOnlyCallDurationMoreThan5,
      togglepayoutOnlyCallDurationMoreThan5,
    };
  },
});
</script>

<style scoped>
/* Optional styling */
</style>

<template>
  <div class="flex flex-col">
    <div
      v-if="companyProgramsQueryLoading"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <div class="flex flex-col w-full p-6">
      <span class="text-black text-3xl font-semibold leading-9 text-left"
        >Programs</span
      >
    </div>

    <div class="flex flex-wrap w-full p-6 gap-4">
      <div
        v-for="(program, index) in companyPrograms"
        :key="program.id"
        class="flex flex-col flex-1 justify-between gap-4 rounded-lg border border-gray-300 p-4 h-48 max-w-[220px]"
      >
        <div
          class="text-gray-900 font-inter text-lg font-medium leading-6 text-left break-words"
        >
          {{ truncateText(program.name, 25) }}
        </div>
        <div class="flex flex-col gap-3">
          <div
            class="text-gray-500 font-inter text-sm font-normal leading-5 text-left break-words"
          >
            {{ truncateText(program.description || "", 40) }}
          </div>
          <div class="flex flex-col">
            <BaseButton
              type="button"
              @click="handleViewProgram(index)"
              class="flex flex-col justify-center items-center p-3 w-full bg-violet-100 text-primary-purple rounded-md text-sm font-medium leading-none text-center"
            >
              View Detail
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { defineComponent, ref, watch } from "vue";
import { Program } from "@/types/expert-program-types";
import BaseButton from "@/components/shared/BaseButton.vue";
import { useUserStore } from "@/stores/user";

const GET_COMPANY_PROGRAMS = gql`
  query getCompanyPrograms {
    getCompanyPrograms {
      id
      name
      description
    }
  }
`;

export default defineComponent({
  name: "ProgramsList",
  components: {
    BaseButton,
  },
  emits: ["programSelected"],
  setup(_, { emit }) {
    const companyPrograms = ref<Program[]>([]);
    const userStore = useUserStore();

    const {
      result: companyProgramsQueryResult,
      error: companyProgramsQueryError,
      loading: companyProgramsQueryLoading,
      refetch: companyProgramsQueryRefetch,
    } = useQuery(GET_COMPANY_PROGRAMS);

    const truncateText = (text: string, maxLength = 50) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      } else if (text.length == 0) {
        return "No description provided.";
      }
      return text;
    };

    const handleViewProgram = (index: number) => {
      userStore.setSelectedProgram(companyPrograms.value[index]);
      emit("programSelected", userStore.selectedProgram);
    };

    companyProgramsQueryRefetch();

    watch(
      () => companyProgramsQueryResult.value,
      (newValue) => {
        if (newValue) {
          companyPrograms.value = newValue.getCompanyPrograms;
          console.log("companyPrograms count", companyPrograms.value.length);
          if (companyPrograms.value.length === 1) {
            handleViewProgram(0);
          }
        }
      },
      { immediate: true }
    );

    watch(
      () => companyProgramsQueryError.value,
      (newValue) => {
        if (newValue) {
          console.log("got error", newValue);
        }
      },
      { immediate: true }
    );

    return {
      // companyProgramsQueryResult,
      // companyProgramsQueryError,
      companyPrograms,
      truncateText,
      handleViewProgram,
      companyProgramsQueryLoading,
    };
  },
});
</script>

<template>
  <div
    v-if="isLoading"
    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
  >
    <!-- Spinner -->
    <div
      class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
    ></div>
  </div>
  <table class="min-w-full border border-gray-300 mb-8">
    <thead class="justify-between">
      <tr class="border-b border-gray-300">
        <th
          class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
        >
          Name
        </th>
        <th
          class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
        >
          Email
        </th>
        <th
          class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
        >
          Requested At
        </th>
        <th
          class="px-4 py-2 text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
        ></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, index) in requestsRows"
        :key="row.id"
        class="hover:bg-gray-50 text-left"
      >
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          <div class="flex flex-col">
            <span>{{ row.user.firstName + " " + row.user.lastName }}</span>
          </div>
        </td>
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          <div class="flex flex-col">
            <span>{{ row.user.email }}</span>
          </div>
        </td>
        <td class="px-4 py-2 font-medium text-xs leading-4 border-b">
          <span class="px-2 rounded-lg">{{
            convertToDate(row.createdAt)
          }}</span>
        </td>
        <td class="flex justify-end px-4 py-2">
          <BaseButton
            type="button"
            class="flex justify-center items-center py-2 px-4 rounded-md bg-indigo-100 text-primary-purple"
            style="width: auto"
            @click="handleReviewEntry(index)"
          >
            Approve
          </BaseButton>
        </td>
      </tr>
    </tbody>
  </table>
  <ReviewEntryModal
    v-if="showReviewEntryModal"
    :isOpen="showReviewEntryModal"
    :onClose="() => (showReviewEntryModal = false)"
    :expert="requestsRows[selectedRequestIndex]?.user"
    :requestId="requestsRows[selectedRequestIndex]?.id"
    @refreshRequests="fetchRequests"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { RequestRow } from "@/types/dashboard-types";
import ReviewEntryModal from "./ReviewEntryModal.vue";

const LIST_REQUESTS_QUERY = gql`
  query getCompanyJoinProgramRequest($status: Int!) {
    getCompanyJoinProgramRequest(status: $status) {
      id
      status
      createdAt
      user {
        id
        firstName
        lastName
        email
        uuid
      }
    }
  }
`;

export default defineComponent({
  name: "RequestsList",
  components: {
    BaseButton,
    ReviewEntryModal,
  },
  props: {
    handleError: {
      type: Function,
      required: true,
    },
    openProfileUuid: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const requestsRows = ref<RequestRow[]>([]);
    const showReviewEntryModal = ref<boolean>(false);
    const selectedRequestIndex = ref<number>(0);
    const isLoading = ref(false);

    const fetchRequests = () => {
      isLoading.value = true;
      console.log("fetchRequests ");
      const { onResult: onResultRequests, onError: onErrorRequests } = useQuery(
        LIST_REQUESTS_QUERY,
        { status: 0 },
        { fetchPolicy: "network-only" }
      );

      onResultRequests((result) => {
        if (result) {
          if (result.data) {
            requestsRows.value = result.data.getCompanyJoinProgramRequest;
            checkAutoProfileSelection();
          }
          isLoading.value = false;
        }
      });

      onErrorRequests((error) => {
        if (error) {
          props.handleError(error.message);
          console.log("error ", error);
          isLoading.value = false;
        }
      });
    };

    const checkAutoProfileSelection = () => {
      if (props.openProfileUuid) {
        const profile = requestsRows.value.find(
          (row) => row.user.uuid === props.openProfileUuid
        );
        if (profile) {
          handleReviewEntry(requestsRows.value.indexOf(profile));
        }
      }
    };

    const convertToDate = (date: string) => {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const handleReviewEntry = (index: number) => {
      selectedRequestIndex.value = index;
      showReviewEntryModal.value = true;
    };

    onMounted(() => {
      fetchRequests();
    });

    return {
      requestsRows,
      convertToDate,
      handleReviewEntry,
      showReviewEntryModal,
      selectedRequestIndex,
      fetchRequests,
      isLoading,
    };
  },
});
</script>

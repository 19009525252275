import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"
import _imports_0 from '../../../../assets/shared/failure.svg'


const _hoisted_1 = { class: "overflow-x-auto w-full pr-4" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex justify-center" }
const _hoisted_4 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = {
  key: 0,
  class: "flex items-center gap-2 bg-yellow-100 p-2 rounded-lg"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "flex items-center gap-2 bg-green-100 p-2 rounded-lg"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "flex flex-row gap-2" }
const _hoisted_11 = {
  key: 0,
  class: "flex-1 border border-gray-200 rounded-lg flex flex-col"
}
const _hoisted_12 = { class: "flex-1" }
const _hoisted_13 = {
  key: 1,
  class: "flex-1 border border-gray-200 rounded-lg flex flex-col"
}
const _hoisted_14 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewDirectory = _resolveComponent("ViewDirectory")!
  const _component_ViewWidget = _resolveComponent("ViewWidget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Error Icon",
                  class: "w-4 h-4"
                })
              ], -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.directoryViewCount === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", {
                innerHTML: _ctx.ALERT_ICON,
                class: "text-gray-500"
              }, null, 8, _hoisted_7),
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-gray-500 text-left font-inter text-md font-normal leading-8" }, " It seems your directory hasn't been installed/viewed yet. ", -1))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", {
                innerHTML: _ctx.HOLLOW_CHECK_CIRCLE,
                class: "text-green-700"
              }, null, 8, _hoisted_9),
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-green-700 text-left font-inter text-md font-normal leading-8" }, " Your directory has started gaining views. ", -1))
            ]))
      ]),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.isDirectoryVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_ViewDirectory, {
                  setErrorMessage: _ctx.setErrorMessage,
                  setDirectoryViewCount: _ctx.setDirectoryViewCount,
                  onHideWidgets: _ctx.hideWidgets
                }, null, 8, ["setErrorMessage", "setDirectoryViewCount", "onHideWidgets"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.isWidgetVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_ViewWidget, { onHideDirectory: _ctx.hideDirectory }, null, 8, ["onHideDirectory"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
<template>
  <main class="bg-white min-h-screen flex flex-col">
    <div class="flex justify-center mb-4">
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
        style="max-height: 80px; overflow-y: auto"
      >
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <img
            src="@/assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>
    </div>
    <div
      class="flex flex-wrap items-center pt-2 pr-2 pl-3.5 text-xs whitespace-nowrap text-zinc-700 max-md:pr-5"
    >
      <div
        v-if="isGridUneven"
        class="flex gap-2 bg-gray-100 p-6 justify-center w-full max-md:max-w-full"
      >
        <div v-for="n in columns" :key="n" class="space-y-3">
          <!-- Loop over the profiles array and place them in the respective column div based on index -->
          <ProfileCard
            v-for="profile in profiles.filter(
              (_, index) => index % columns === n - 1
            )"
            :key="profile.id"
            :profile="profile"
            :isCentered="profileCenterAligned"
            :isGridUneven="isGridUneven"
            :profileImageClass="profileImageClass"
            :profileImageAndNameLayoutClass="profileImageAndNameLayoutClass"
            :fontFamily="fontFamily"
            :boldness="boldness"
            :textColor="textColor"
            :backgroundColor="backgroundColor"
            :fontSize="fontSize"
            :enableBookMeeting="true"
            :programLogo="programLogo"
            :programUuid="programUuid as string"
            class="flex-shrink-0"
          />
        </div>
      </div>
      <div v-else>
        <div
          class="flex flex-wrap bg-gray-100 p-6 justify-center w-full max-md:max-w-full"
          :class="profileCardsGap"
        >
          <ProfileCard
            v-for="profile in profiles"
            :key="profile.id"
            :profile="profile"
            :isCentered="profileCenterAligned"
            :isGridUneven="isGridUneven"
            :profileImageClass="profileImageClass"
            :profileImageAndNameLayoutClass="profileImageAndNameLayoutClass"
            :fontFamily="fontFamily"
            :boldness="boldness"
            :textColor="textColor"
            :backgroundColor="backgroundColor"
            :fontSize="fontSize"
            :enableBookMeeting="true"
            :programLogo="programLogo"
            :programUuid="programUuid as string"
            class="flex-shrink-0"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center mt-2">
      <BaseButton
        type="button"
        v-if="hasMoreExperts"
        @click="loadMoreExperts"
        class="flex overflow-hidden flex-wrap gap-3 justify-center items-center self-stretch py-2.5 pr-4 pl-4 my-auto text-violet-600 rounded-md max-md:max-w-full"
      >
        <span
          class="shadow-sm hover:shadow-md transition-shadow duration-200 ease-in-out p-2 rounded-md"
        >
          Load More Experts
        </span>
      </BaseButton>
    </div>
  </main>
</template>

<script lang="ts">
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import ProfileCard from "@/components/expert-program/directory/ProfileCard.vue";
import { EXPERTS_DIRECTORY_PAGE_SIZE } from "@/data/constants";
import BaseButton from "@/components/shared/BaseButton.vue";
import { googleFontFamiliesLink } from "@/data/expert-program/font-families";
// import { template } from "lodash";

const GET_EXPERTS = gql`
  query getExperts(
    $companyId: ID!
    $programUuid: String!
    $first: Int!
    $origin: String!
  ) {
    getCompanyExperts(
      companyId: $companyId
      programUuid: $programUuid
      first: $first
      origin: $origin
    ) {
      edges {
        node {
          id
          email
          firstName
          lastName
          profile_picture
          linkedin_profile
          country
          uuid
          title
          companyName
          userProgramProfiles(programUuid: $programUuid) {
            expertise
            experience
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_DIRECTORY = gql`
  query getDirectory($companyId: ID!, $origin: String!) {
    getDirectoryDetail(companyId: $companyId, origin: $origin) {
      id
      style
      programLogo
      directoryCosmetic {
        fontFamily
        bold
        textColor
        backgroundColor
        fontSize
      }
    }
  }
`;

const VISIT_MUTATION = gql`
  mutation updateDirectoryVisit($input: VisitInput!) {
    updateDirectoryVisit(input: $input) {
      success
    }
  }
`;

interface UserProgramProfile {
  expertise: number;
  experience: number;
}

interface Profile {
  id: string;
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  companyName: string;
  location: string;
  imageUrl: string;
  linkedinUrl: string;
  country: string;
  userProgramProfile: UserProgramProfile[]; // An array containing user program profiles
}

interface Edge {
  node: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    country: string;
    profile_picture: string;
    linkedin_profile: string;
    title: string;
    companyName: string;
    uuid: string;
    userProgramProfiles: UserProgramProfile[];
  };
}

interface profileCardCosmetics {
  backgroundColor: string;
  bold: string;
  fontFamily: string;
  fontSize: string;
  textColor: string;
}

export default defineComponent({
  name: "ExpertsDirectory",
  components: {
    ProfileCard,
    BaseButton,
  },
  mounted() {
    const link = document.createElement("link");
    link.href = googleFontFamiliesLink;
    link.rel = "stylesheet";
    document.head.appendChild(link);
  },
  setup() {
    const route = useRoute();
    const companyId = ref(route.query.company_id);
    const programUuid = ref(route.query.program);
    const profiles = ref<Profile[]>([]);
    const errorMessage = ref<string>("");
    const numberOfExperts = ref(EXPERTS_DIRECTORY_PAGE_SIZE);
    const hasMoreExperts = ref(false);
    const fontFamily = ref<string>("");
    const boldness = ref<string>("");
    const textColor = ref<string>("");
    const backgroundColor = ref<string>("");
    const fontSize = ref<string>("");
    const profileImageClass = ref<string>("");
    const profileCardsGap = ref<string>("");
    const profileImageAndNameLayoutClass = ref<string>("");
    const profileCenterAligned = ref(false);
    const columns = ref(5);
    const isGridUneven = ref(false);
    const origin = document.location.ancestorOrigins[0] || "";
    const programLogo = ref("");

    const {
      mutate: updateDirectoryVisit,
      onDone: updateDirectoryVisitDone,
      onError: updateDirectoryVisitError,
    } = useMutation(VISIT_MUTATION);

    const {
      result: queryExpertsResult,
      error: queryExpertsError,
      refetch: refetchExperts,
    } = useQuery(GET_EXPERTS, {
      companyId: companyId.value,
      programUuid: programUuid.value,
      first: numberOfExperts.value,
      origin: origin,
    });

    const { result: directoryResult, error: directoryError } = useQuery(
      GET_DIRECTORY,
      {
        companyId: companyId.value,
        origin: origin,
      }
    );

    watch(
      () => directoryResult.value,
      (newValue) => {
        if (newValue && newValue.getDirectoryDetail) {
          // console.log(
          //   "Directory result is ",
          //   newValue.getDirectoryDetail.style
          // );
          programLogo.value = newValue.getDirectoryDetail.programLogo;
          applyCardTemplate(newValue.getDirectoryDetail.style);
          applyCardStyles(newValue.getDirectoryDetail.directoryCosmetic);
        }
      }
    );

    watch(
      () => directoryError.value,
      (newError) => {
        if (newError) {
          console.log("Directory error is ", newError);
          errorMessage.value = newError.message;
        }
      }
    );

    watch(
      () => queryExpertsResult.value,
      (newValue) => {
        if (newValue) {
          profiles.value = newValue.getCompanyExperts.edges.map(
            (edge: Edge) => ({
              id: edge.node.id,
              firstName: edge.node.firstName,
              lastName: edge.node.lastName,
              email: edge.node.email,
              imageUrl: edge.node.profile_picture,
              linkedinUrl: edge.node.linkedin_profile,
              country: edge.node.country,
              uuid: edge.node.uuid,
              title: edge.node.title,
              companyName: edge.node.companyName,
              userProgramProfile: edge.node.userProgramProfiles,
            })
          );
          hasMoreExperts.value =
            newValue.getCompanyExperts.pageInfo.hasNextPage;
        }
      },
      { immediate: true }
    );

    watch(
      () => queryExpertsError.value,
      (newError) => {
        if (newError) {
          console.log("Error happened ", newError);
          errorMessage.value = newError.message;
        }
      }
    );

    const loadMoreExperts = () => {
      numberOfExperts.value += EXPERTS_DIRECTORY_PAGE_SIZE;
      refetchExperts({
        companyId: companyId.value,
        programUuid: programUuid.value,
        first: numberOfExperts.value, // Pass the updated numberOfExperts
        origin: origin,
      });
    };

    const applyCardStyles = (profileCosmetic: profileCardCosmetics) => {
      fontFamily.value = profileCosmetic.fontFamily;
      boldness.value = profileCosmetic.bold;
      textColor.value = profileCosmetic.textColor;
      backgroundColor.value = profileCosmetic.backgroundColor;
      fontSize.value = profileCosmetic.fontSize;
    };

    const applyCardTemplate = (template: string) => {
      // console.log("template ", template);
      updateProfileImageClass(template);
      updateProfileCardsGap(template);
      updateProfileImageAndNameLayoutClass(template);
      updateProfileCenterAlignment(template);
      updateGridLayout(template);
    };

    const updateProfileImageClass = (template: string) => {
      if (template === "template-1" || template === "template-4") {
        profileImageClass.value = "rounded-full";
      } else {
        profileImageClass.value = "";
      }
    };

    const updateProfileCardsGap = (template: string) => {
      if (template === "template-3") {
        profileCardsGap.value = "gap-0.5";
      } else {
        profileCardsGap.value = "gap-3";
      }
    };

    const updateProfileImageAndNameLayoutClass = (template: string) => {
      if (template === "template-3" || template === "template-5") {
        profileImageAndNameLayoutClass.value = "flex-col";
      } else {
        profileImageAndNameLayoutClass.value = "flex-row";
      }
    };

    const updateProfileCenterAlignment = (template: string) => {
      // console.log("template for center alignment ", template);
      if (template === "template-5") {
        profileCenterAligned.value = true;
      } else {
        profileCenterAligned.value = false;
      }
    };

    const updateGridLayout = (template: string) => {
      if (template === "template-4") {
        isGridUneven.value = true;
      } else {
        isGridUneven.value = false;
      }
    };

    const breakpoints = {
      sm: 640, // Small screens
      md: 768, // Medium screens
      lg: 1080, // Large screens
      xl: 1420, // Extra-large screens
      // 2xl: 1536, // 2X Large screens
    };

    const getColumnCount = () => {
      const width = window.innerWidth;

      if (width < breakpoints.md) {
        return 1;
      } else if (width < breakpoints.lg) {
        return 2;
      } else if (width < breakpoints.xl) {
        return 3;
      } else {
        return 4;
      }
    };

    const handleResize = () => {
      columns.value = getColumnCount();
    };

    const callUpdateDirectoryVisit = () => {
      updateDirectoryVisit({
        input: {
          directoryVisitParams: {
            programId: programUuid.value,
          },
        },
      });

      updateDirectoryVisitDone((response) => {
        if (response) {
          if (response.data.updateDirectoryVisit.success === false) {
            errorMessage.value = "Failed to update directory visit";
          }
        }
      });

      updateDirectoryVisitError((error: ApolloError) => {
        if (error) {
          console.log("Error is ", error);
          errorMessage.value = error.message;
        }
      });
    };

    const observer = new MutationObserver(() => {
      const tinyChatBubble = document.querySelector("tiny-chat");

      if (tinyChatBubble) {
        (tinyChatBubble as HTMLElement).style.display = "none";
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    handleResize();

    onMounted(() => {
      callUpdateDirectoryVisit();
      window.addEventListener("resize", () => {
        handleResize();
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      companyId,
      programUuid,
      profiles,
      errorMessage,
      hasMoreExperts,
      loadMoreExperts,
      fontFamily,
      boldness,
      textColor,
      backgroundColor,
      fontSize,
      profileImageClass,
      profileCardsGap,
      profileImageAndNameLayoutClass,
      profileCenterAligned,
      columns,
      isGridUneven,
      programLogo,
    };
  },
});
</script>

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../../../assets/shared/failure.svg'


const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 flex justify-end bg-black bg-opacity-50 z-50 overflow-hidden"
}
const _hoisted_2 = { class: "bg-white w-1/3 h-full p-6 shadow-lg transform transition-transform ease-in-out duration-300 flex flex-col" }
const _hoisted_3 = { class: "flex-1 overflow-y-auto pr-4" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "flex justify-end" }
const _hoisted_6 = { class: "flex justify-center mb-4" }
const _hoisted_7 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_8 = { class: "text-sm text-left" }
const _hoisted_9 = { class: "flex flex-col gap-2 text-left font-inter" }
const _hoisted_10 = {
  key: 0,
  class: "flex w-12 h-12 overflow-hidden rounded-full"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  class: "flex items-center justify-center w-12 h-12 bg-primary-purple text-white rounded-full"
}
const _hoisted_13 = { class: "flex flex-col" }
const _hoisted_14 = { class: "text-2xl font-bold" }
const _hoisted_15 = { class: "text-sm text-gray-500" }
const _hoisted_16 = { class: "flex flex-col border-b border-gray-200 pb-4 gap-2" }
const _hoisted_17 = { class: "flex flex-row gap-1 space-x-4" }
const _hoisted_18 = { class: "flex flex-col border-b border-gray-200 pb-4 gap-2" }
const _hoisted_19 = { class: "flex flex-row gap-1 space-x-4" }
const _hoisted_20 = { class: "flex flex-col" }
const _hoisted_21 = { class: "relative" }
const _hoisted_22 = ["src"]
const _hoisted_23 = {
  key: 0,
  class: "absolute inset-0 flex items-center justify-center bg-gray-800 text-white text-lg font-semibold z-10"
}
const _hoisted_24 = { class: "flex flex-col border-b border-gray-200 pb-4" }
const _hoisted_25 = { class: "text-sm" }
const _hoisted_26 = { class: "flex flex-col border-b border-gray-200 pb-4" }
const _hoisted_27 = { class: "text-sm" }
const _hoisted_28 = { class: "flex flex-col border-b border-gray-200 pb-4" }
const _hoisted_29 = { class: "text-sm" }
const _hoisted_30 = { class: "flex flex-col border-b border-gray-200 pb-4" }
const _hoisted_31 = { class: "text-sm" }
const _hoisted_32 = { class: "flex flex-col border-b border-gray-200 pb-4" }
const _hoisted_33 = { class: "text-sm" }
const _hoisted_34 = { class: "flex flex-col border-b border-gray-200 pb-4" }
const _hoisted_35 = { class: "text-sm" }
const _hoisted_36 = { class: "flex justify-end gap-2 border-t border-gray-200 pt-4 bg-white sticky bottom-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!

  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_BaseButton, {
                  type: "button",
                  onClick: _ctx.closeModal,
                  class: "mb-4 text-gray-500 justify-end hover:text-gray-700"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" X ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_ctx.errorMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                        _createElementVNode("img", {
                          src: _imports_0,
                          alt: "Error Icon",
                          class: "w-4 h-4"
                        })
                      ], -1)),
                      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.errorMessage), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.profilePicture)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("img", {
                        loading: "lazy",
                        src: _ctx.profilePictureUrl,
                        alt: "User profile picture",
                        class: "w-full h-full object-cover"
                      }, null, 8, _hoisted_11)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.initials()), 1)),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.expert.firstName) + " " + _toDisplayString(_ctx.expert.lastName), 1),
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.expert.email), 1)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_BaseLabel, {
                    forAttr: "expertise",
                    text: "Expertise",
                    class: "text-black text-sm font-medium leading-5 text-left"
                  }),
                  _createElementVNode("div", _hoisted_17, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
                      return _createElementVNode("div", {
                        key: index,
                        class: _normalizeClass(["w-3 h-3 rounded-full", 
                    index <= _ctx.expertise
                      ? 'bg-primary-purple'
                      : 'bg-white border-2 border-gray-500'
                  ])
                      }, null, 2)
                    }), 64))
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_BaseLabel, {
                    forAttr: "experience",
                    text: "Experience",
                    class: "text-black text-sm font-medium leading-5 text-left"
                  }),
                  _createElementVNode("div", _hoisted_19, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
                      return _createElementVNode("div", {
                        key: index,
                        class: _normalizeClass(["w-3 h-3 rounded-full", 
                    index <= _ctx.experience
                      ? 'bg-primary-purple'
                      : 'bg-white border-2 border-gray-500'
                  ])
                      }, null, 2)
                    }), 64))
                  ])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-black text-sm font-medium leading-5 text-left" }, " Uploaded Video ", -1)),
                  _createElementVNode("div", _hoisted_21, [
                    (_openBlock(), _createElementBlock("video", {
                      ref: "video",
                      class: "w-full h-auto border border-gray-300",
                      controls: "",
                      type: "video/mp4",
                      onCanplay: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCanPlay && _ctx.handleCanPlay(...args))),
                      onLoadstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleLoadStart && _ctx.handleLoadStart(...args))),
                      key: _ctx.introductionVideo,
                      src: _ctx.introductionVideo || ''
                    }, null, 40, _hoisted_22)),
                    (_ctx.isVideoLoading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_23, " Loading video... "))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createVNode(_component_BaseLabel, {
                    forAttr: "title",
                    text: "Title",
                    class: "text-black text-sm font-medium leading-5 text-left"
                  }),
                  _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.title), 1)
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_BaseLabel, {
                    forAttr: "companyName",
                    text: "Company Name",
                    class: "text-black text-sm font-medium leading-5 text-left"
                  }),
                  _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.companyName), 1)
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createVNode(_component_BaseLabel, {
                    forAttr: "country",
                    text: "Country",
                    class: "text-black text-sm font-medium leading-5 text-left"
                  }),
                  _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.country), 1)
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_component_BaseLabel, {
                    forAttr: "state",
                    text: "State",
                    class: "text-black text-sm font-medium leading-5 text-left"
                  }),
                  _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.state), 1)
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _createVNode(_component_BaseLabel, {
                    forAttr: "industry",
                    text: "Industry",
                    class: "text-black text-sm font-medium leading-5 text-left"
                  }),
                  _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.industry), 1)
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createVNode(_component_BaseLabel, {
                    forAttr: "linkedinProfile",
                    text: "LinkedIn Profile",
                    class: "text-black text-sm font-medium leading-5 text-left"
                  }),
                  _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.linkedinProfile), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_36, [
            _createVNode(_component_BaseButton, {
              type: "button",
              onClick: _ctx.handleReject,
              class: "bg-white text-black rounded-md border border-gray-300 px-4 py-2"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Reject ")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_BaseButton, {
              type: "button",
              onClick: _ctx.handleAccept,
              class: "bg-primary-purple text-white rounded-md px-4 py-2"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Approve ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
<template>
  <main class="bg-white min-h-screen flex flex-col">
    <div class="flex flex-grow max-md:flex-col">
      <section
        class="flex flex-col w-1/2 justify-center px-2 py-6 max-md:w-full"
      >
        <div
          class="flex flex-col w-1/2 mx-auto justify-center max-md:mt-4 max-md:w-full"
        >
          <h1
            class="text-3xl font-semibold leading-9 text-center text-zinc-800"
          >
            Sign Up
          </h1>
          <p class="mt-3 text-base leading-6 text-center text-neutral-700">
            Create your account to get started
          </p>
          <div
            v-if="errorMessage"
            role="alert"
            class="flex gap-2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          >
            <!-- Icon Container -->
            <div
              class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
            >
              <!-- Custom Image Icon -->
              <img
                src="../assets/shared/failure.svg"
                alt="Error Icon"
                class="w-4 h-4"
              />
            </div>
            <p>{{ errorMessage }}</p>
          </div>
          <form @submit.prevent="handleSubmit" class="flex flex-col gap-2">
            <div class="flex gap-5 justify-between mt-8">
              <div class="flex flex-col flex-grow basis-1/2">
                <BaseLabel
                  forAttr="firstName"
                  text="First Name"
                  class="text-sm font-medium leading-5 text-zinc-800 text-left"
                />
                <BaseInput
                  type="text"
                  id="firstName"
                  v-model="firstName"
                  className="flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                  placeholder="John"
                  required
                />
              </div>
              <div class="flex flex-col flex-grow basis-1/2">
                <BaseLabel
                  forAttr="lastName"
                  text="Last Name"
                  class="text-sm font-medium leading-5 text-zinc-800 text-left"
                />
                <BaseInput
                  type="text"
                  id="lastName"
                  v-model="lastName"
                  className="flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                  placeholder="Doe"
                  required
                />
              </div>
            </div>
            <div class="mt-6 text-left">
              <BaseLabel
                forAttr="email"
                text="Work Email"
                class="text-sm font-medium leading-5 text-zinc-800"
              />
              <BaseInput
                id="email"
                v-model="email"
                type="email"
                placeholder="john@acme.com"
                className="px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                required
              />
            </div>
            <div class="mt-6 text-left" v-if="!invitationToken">
              <BaseLabel
                forAttr="companyName"
                text="Company Name"
                class="text-sm font-medium leading-5 text-zinc-800"
              />
              <BaseInput
                id="companyName"
                v-model="companyName"
                placeholder="Acme Corporation"
                className="px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                required
              />
            </div>
            <div
              v-if="!invitationToken"
              class="flex flex-col mt-6 w-full text-sm leading-none text-left mb-6"
            >
              <label for="softwareUrl" class="font-medium text-zinc-800"
                >Company Website</label
              >
              <div
                class="flex overflow-hidden items-center mt-1 w-full text-gray-500 whitespace-nowrap rounded-md border border-gray-300 border-solid"
              >
                <span
                  class="self-stretch py-2.5 pr-3 pl-3.5 bg-gray-100 rounded-md"
                  >https://</span
                >
                <input
                  type="text"
                  id="url"
                  v-model="companyWebsite"
                  class="overflow-hidden flex-1 shrink self-stretch py-2.5 pr-3.5 pl-3 my-auto bg-white rounded-none"
                  placeholder="www.example.com"
                  required
                />
              </div>
            </div>
            <div class="flex flex-col flex-grow basis-1/2">
              <BaseLabel
                forAttr="title"
                text="Your Title"
                class="text-sm font-medium leading-5 text-zinc-800 text-left"
              />
              <BaseInput
                type="text"
                id="title"
                v-model="title"
                className="flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                required
              />
            </div>
            <div class="mt-6 text-left">
              <BaseLabel
                forAttr="password"
                text="Set-up Password"
                class="text-sm font-medium leading-5 text-zinc-800"
              />
              <div class="relative">
                <BaseInput
                  :type="showPassword ? 'text' : 'password'"
                  id="password"
                  v-model="password"
                  className="px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                  required
                />
                <button
                  type="button"
                  @click="togglePasswordVisibility"
                  class="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  <i
                    :class="{
                      'fas fa-eye': showPassword,
                      'fas fa-eye-slash': !showPassword,
                    }"
                    :title="showPassword ? 'Hide password' : 'Show password'"
                    class="w-5 h-5 cursor-pointer"
                  ></i>
                </button>
              </div>
            </div>
            <PasswordStrength
              :password="password"
              :confirmPassword="confirmPassword"
            />
            <div class="mt-6 text-left">
              <BaseLabel
                forAttr="confirmPassword"
                text="Confirm Password"
                class="text-sm font-medium leading-5 text-zinc-800"
              />
              <div class="relative">
                <BaseInput
                  :type="showConfirmPassword ? 'text' : 'password'"
                  id="confirmPassword"
                  v-model="confirmPassword"
                  className="px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                  required
                />
                <button
                  type="button"
                  @click="toggleConfirmPasswordVisibility"
                  class="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  <i
                    :class="{
                      'fas fa-eye': showConfirmPassword,
                      'fas fa-eye-slash': !showConfirmPassword,
                    }"
                    :title="
                      showConfirmPassword ? 'Hide password' : 'Show password'
                    "
                    class="w-5 h-5 cursor-pointer"
                  ></i>
                </button>
              </div>
            </div>
            <div class="flex items-center mt-6 text-base font-medium leading-6">
              <CheckBox
                v-model="agreedToTerms"
                id="termsAgreement"
                base-classes="relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none"
                checked-classes="bg-violet-600 border-violet-600"
                unchecked-classes="bg-white border-violet-600"
                label-classes="text-gray-500 ml-2"
              >
                I've read and agree with
                <a
                  href="https://www.slashexperts.com/terms"
                  target="_blank"
                  class="text-violet-600 ml-1"
                  >Terms & Conditions</a
                >
              </CheckBox>
            </div>

            <BaseButton
              type="submit"
              :className="buttonClasses"
              :disabled="disableButton"
            >
              Continue
            </BaseButton>
          </form>
          <div
            class="flex gap-2 justify-center items-center px-px mt-6 text-sm leading-5 text-center text-gray-500"
          >
            <div
              class="flex-1 shrink-0 self-stretch my-auto h-px bg-gray-300"
            ></div>
            <span class="self-stretch">Or</span>
            <div
              class="flex-1 shrink-0 self-stretch my-auto h-px bg-gray-300"
            ></div>
          </div>
          <p
            class="flex gap-1 justify-center px-16 mt-6 text-base font-medium leading-6 max-md:px-5"
          >
            <span class="text-gray-500">Already have an account?</span>
            <span
              class="font-medium text-violet-600 cursor-pointer"
              @click="navigateToSignIn"
              >Sign in</span
            >
          </p>
        </div>
      </section>
      <aside
        class="flex flex-col ml-5 w-1/2 max-md:ml-0 max-md:w-full bg-primary-purple"
      >
        <div class="flex flex-col mt-14 flex-grow">
          <div class="flex flex-col ml-10">
            <!-- First Image -->
            <div class="flex w-full self-start">
              <img
                src="../assets/signup/headline.svg"
                alt="Top Image"
                class="w-full h-auto max-w-full object-contain"
              />
            </div>

            <!-- Second Image (Left Aligned) -->
            <div class="flex w-1/2 mt-14 self-start">
              <img
                src="../assets/signup/bullet-points.svg"
                alt="Middle Image"
                class="w-full h-auto max-w-full object-contain"
              />
            </div>
          </div>

          <!-- Third Image, or Footer Image -->
          <div class="flex justify-center mt-auto">
            <img
              src="../assets/signup/footer.svg"
              alt="Footer Image"
              class="w-full h-auto max-w-full object-contain"
            />
          </div>
        </div>
      </aside>
    </div>
  </main>
  <div
    v-if="environment === 'Staging'"
    id="company-widget-container"
    data-widget-id="677a7881538ef93671f29606:677a7881538ef93671f29608"
  ></div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";
import axios, { AxiosError } from "axios";
import PasswordStrength from "@/components/sign-up/PasswordStrength.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import CheckBox from "@/components/shared/CheckBox.vue";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import { PostHog } from "posthog-js";
import { useRouter, useRoute } from "vue-router";
import { ErrorResponse } from "@/types/interfaces";
import { useUserStore } from "@/stores/user";
export default defineComponent({
  name: "CompanySignUp",
  components: {
    PasswordStrength,
    BaseButton,
    BaseInput,
    CheckBox,
    BaseLabel,
  },
  setup() {
    const firstName = ref("");
    const lastName = ref("");
    const companyName = ref("");
    const email = ref("");
    const title = ref("");
    const companyWebsite = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const agreedToTerms = ref(false);
    const showPassword = ref(false);
    const environment = ref(process.env.VUE_APP_ENV);
    const showConfirmPassword = ref(false);
    const errorMessage = ref<string | null>(null);
    const isSubmitting = ref(false);
    const tempDisable = ref(true);
    const userStore = useUserStore();
    const router = useRouter();
    const route = useRoute();
    const invitationToken = ref(route.query.token);
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;
    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };
    const toggleConfirmPasswordVisibility = () => {
      showConfirmPassword.value = !showConfirmPassword.value;
    };
    const handleSubmit = async () => {
      isSubmitting.value = true;
      tempDisable.value = false;
      errorMessage.value = null;
      const validationFailure = validateForm();
      if (validationFailure) {
        errorMessage.value = validationFailure;
        isSubmitting.value = false;
        tempDisable.value = true;
        return;
      }
      try {
        const response = await handleRequest();
        if (response.data.token) {
          console.log("response", response.data);
          handleSignUpSuccess(response.data.token, response.data.uuid);
          await navigateToOnboarding();
        } else {
          errorMessage.value = "Something went wrong, please try again";
        }
      } catch (error) {
        console.error("Error during sign-up:", error);
        window.scrollTo({ top: 0, behavior: "smooth" });
        errorMessage.value = processErrorResponse(
          error as AxiosError<ErrorResponse>
        );
      } finally {
        isSubmitting.value = false;
        tempDisable.value = true;
      }
    };
    const validateForm = (): string => {
      return validate();
    };
    const handleRequest = async () => {
      const url = process.env.VUE_APP_SIGN_UP_URL;
      const data = {
        company: {
          name: companyName.value,
          website: formatWebsite(companyWebsite.value),
        },
        company_user: {
          first_name: firstName.value,
          last_name: lastName.value,
          email: email.value,
          title: title.value,
          password: password.value,
          password_confirmation: confirmPassword.value,
        },
        invite_token: invitationToken.value,
      };
      return axios.post(url, data);
    };
    const handleSignUpSuccess = (token: string, uuid: string) => {
      setAuthStore(token);
      produktlyIdentifyUser(uuid, email.value);
      notifyPosthog(uuid);
    };

    const produktlyIdentifyUser = (uuid: string, email: string) => {
      if (window.Produktly) {
        window.Produktly.identifyUser(uuid, {
          email: email,
        });
      }
    };

    const notifyPosthog = (uuid: string) => {
      if (posthog) {
        if (invitationToken.value) {
          posthog.capture("Company_User_Clicked_On_Invite_Signup", {
            email: email.value,
          });
        } else {
          posthog.capture("Company_User_Clicked_Signup", {
            email: email.value,
            company_name: companyName.value,
          });
        }

        posthog.identify(uuid, {
          email: email.value,
          company_email: email.value,
          company_first: firstName.value,
          company_last: lastName.value,
          expert_user: false,
          company_user: true,
          attendee_user: false,
          company_company: companyName.value,
          company_title: title.value,
        });
      }
    };

    const setAuthStore = (token: string) => {
      userStore.clearStore();
      userStore.setToken(token);
      userStore.setEmail(email.value);
      userStore.setFirstName(firstName.value);
      userStore.setLastName(lastName.value);
      userStore.setCompanyName(companyName.value);
      userStore.setCompanyWebsite(companyWebsite.value);
    };
    const navigateToOnboarding = async () => {
      if (userStore.token) {
        if (invitationToken.value) {
          handleInvitedUserSignup(userStore.token);
        } else {
          await router.push({ name: "AddProgramDetails" });
        }
      } else {
        throw new Error("Failed to set auth token");
      }
    };

    const handleInvitedUserSignup = (token: string) => {
      const landingUrl = `${
        process.env.VUE_APP_COMPANY_SIGN_IN_LANDING_URL
      }?token=${encodeURIComponent(token)}`;
      window.open(landingUrl, "_self");
    };

    const processErrorResponse = (error: AxiosError<ErrorResponse>) => {
      if (error.response && error?.response?.data?.errors) {
        return error.response.data.errors.join(",");
      }
      return "An unexpected error occurred. Please retry.";
    };
    const validate = () => {
      if (!agreedToTerms.value) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return "Please agree to the terms and conditions";
      }
      if (!isPasswordValid.value) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return "Password must be 8 characters long, with at least one uppercase and lowercase letter";
      }
      return "";
    };

    const formatWebsite = (url: string) => {
      const trimmedUrl = url.trim();
      return trimmedUrl.startsWith("https://")
        ? trimmedUrl
        : `https://${trimmedUrl}`;
    };

    const navigateToSignIn = () => {
      // const landingUrl = `${process.env.VUE_APP_SIGN_IN_FORM_URL}`;
      // console.log("navigate to sign in", process.env.VUE_APP_SIGN_IN_FORM_URL);
      window.open(process.env.VUE_APP_SIGN_IN_FORM_URL, "_self");
    };

    const isPasswordValid = computed(() => {
      const hasUpperCase = /[A-Z]/.test(password.value);
      const hasLowerCase = /[a-z]/.test(password.value);
      const hasMinLength = password.value.length >= 8;
      return hasUpperCase && hasLowerCase && hasMinLength;
    });

    const isDisabled = computed(() => {
      const basicFieldsFilled =
        firstName.value.length > 0 &&
        lastName.value.length > 0 &&
        email.value.length > 0 &&
        isPasswordValid.value &&
        password.value.length > 0 &&
        confirmPassword.value.length > 0 &&
        title.value.length > 0 &&
        agreedToTerms.value;

      const companyFieldsFilled =
        invitationToken.value ||
        (companyName.value.length > 0 && companyWebsite.value.length > 0);

      return !(basicFieldsFilled && companyFieldsFilled) || isSubmitting.value;
    });

    const buttonClasses = computed(
      () =>
        `w-full px-4 py-2.5 mt-6 text-base font-medium leading-6 text-white whitespace-nowrap bg-violet-600 rounded-md shadow-sm max-md:px-5 ${
          isDisabled.value ? "bg-opacity-60" : ""
        }`
    );
    const disableButton = computed(() => {
      return !tempDisable.value;
    });

    onMounted(() => {
      // Dynamically load the CSS for the widget
      if (environment.value === "Staging") {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href =
          "https://dev-slashexperts-public.s3.us-east-1.amazonaws.com/company-widget/bundle.css";
        document.head.appendChild(link);
        // Dynamically load the JavaScript for the widget
        const script = document.createElement("script");
        script.src =
          "https://dev-slashexperts-public.s3.us-east-1.amazonaws.com/company-widget/bundle.js";
        document.body.appendChild(script);
      }
    });

    return {
      firstName,
      lastName,
      companyName,
      email,
      companyWebsite,
      title,
      password,
      confirmPassword,
      agreedToTerms,
      showPassword,
      showConfirmPassword,
      togglePasswordVisibility,
      toggleConfirmPasswordVisibility,
      handleSubmit,
      isDisabled,
      buttonClasses,
      isPasswordValid,
      errorMessage,
      disableButton,
      tempDisable,
      validate,
      navigateToSignIn,
      invitationToken,
      environment,
    };
  },
});
</script>

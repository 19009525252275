import avatar from "@/assets/shared/avatar-2.svg";
import avatar2 from "@/assets/shared/avatars/avatar-3.svg";
import avatar3 from "@/assets/shared/avatars/avatar-4.svg";
import avatar4 from "@/assets/shared/avatars/avatar-5.svg";
import avatar5 from "@/assets/shared/avatars/avatar-6.svg";
import avatar6 from "@/assets/shared/avatars/avatar-7.svg";
import avatar7 from "@/assets/shared/avatars/avatar-8.svg";
import avatar8 from "@/assets/shared/avatars/avatar-9.svg";
import avatar9 from "@/assets/shared/avatars/avatar-10.svg";

export const directoryProfiles = [
  {
    id: "1",
    uuid: "1",
    firstName: "Courtney",
    lastName: "Henry",
    email: "courtney@example.com",
    country: "United States",
    title: "Software Engineer",
    companyName: "Google",
    imageUrl: avatar,
    linkedinUrl: "",
    userProgramProfile: [
      {
        expertise: 3,
        experience: 4,
      },
    ],
  },
  {
    id: "2",
    uuid: "2",
    firstName: "Alex",
    lastName: "Johnson",
    email: "alex@example.com",
    country: "United Kingdom",
    title: "Product Manager",
    companyName: "Apple",
    imageUrl: avatar2,
    linkedinUrl: "",
    userProgramProfile: [
      {
        expertise: 2,
        experience: 1,
      },
    ],
  },
  {
    id: "3",
    uuid: "3",
    firstName: "Wendy",
    lastName: "Lee",
    email: "wendy@example.com",
    country: "Canada",
    title: "Product Manager",
    companyName: "Amazon",
    imageUrl: avatar3,
    linkedinUrl: "",
    userProgramProfile: [
      {
        expertise: 1,
        experience: 5,
      },
    ],
  },
  {
    id: "4",
    uuid: "4",
    firstName: "Michael",
    lastName: "Chang",
    email: "michael@example.com",
    country: "Singapore",
    title: "Marketing Manager",
    companyName: "Facebook",
    imageUrl: avatar4,
    linkedinUrl: "",
    userProgramProfile: [
      {
        expertise: 2,
        experience: 3,
      },
    ],
  },
  {
    id: "5",
    uuid: "5",
    firstName: "Emma",
    lastName: "Rodriguez",
    email: "emma@example.com",
    country: "Spain",
    title: "UX Researcher",
    companyName: "Twitter",
    imageUrl: avatar5,
    linkedinUrl: "",
    userProgramProfile: [
      {
        expertise: 3,
        experience: 3,
      },
    ],
  },
  {
    id: "6",
    uuid: "6",
    firstName: "Sarah",
    lastName: "Henry",
    email: "sarah@example.com",
    country: "United States",
    title: "Business Analyst",
    companyName: "Google",
    imageUrl: avatar6,
    linkedinUrl: "",
    userProgramProfile: [
      {
        expertise: 4,
        experience: 1,
      },
    ],
  },
  {
    id: "7",
    uuid: "7",
    firstName: "Dave",
    lastName: "Smith",
    email: "dave@example.com",
    country: "United Kingdom",
    title: "Creative Director",
    companyName: "Nike",
    imageUrl: avatar7,
    linkedinUrl: "",
    userProgramProfile: [
      {
        expertise: 5,
        experience: 3,
      },
    ],
  },
  {
    id: "8",
    uuid: "8",
    firstName: "Sarah",
    lastName: "Lee",
    email: "sarah@example.com",
    country: "Canada",
    title: "Financial Analyst",
    companyName: "Hubspot",
    imageUrl: avatar8,
    linkedinUrl: "",
    userProgramProfile: [
      {
        expertise: 5,
        experience: 5,
      },
    ],
  },
  {
    id: "9",
    uuid: "9",
    firstName: "Tom",
    lastName: "Chang",
    email: "tom@example.com",
    country: "Singapore",
    title: "Logistics Coordinator",
    companyName: "Fedex",
    imageUrl: avatar9,
    linkedinUrl: "",
    userProgramProfile: [
      {
        expertise: 1,
        experience: 1,
      },
    ],
  },
];

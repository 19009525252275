<template>
  <div class="flex flex-col justify-center items-center min-h-screen w-full">
    <div class="flex flex-col gap-2 justify-center items-center max-w-max p-4">
      <!-- Error Message Section -->
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg flex-wrap max-w-full max-md:w-full"
        style="max-height: 80px; overflow-y: auto"
      >
        <!-- Icon Container -->
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <img
            src="../../assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>

      <!-- Main Container -->
      <div
        class="flex flex-col lg:flex-row justify-center gap-2 px-5 border border-gray-200 rounded-md w-full max-lg:flex-wrap max-lg:px-3"
      >
        <!-- Meeting Information -->
        <div
          v-if="!meetingCode"
          class="flex flex-col text-left border-b lg:border-b-0 lg:border-r border-gray-200 py-5 px-2 max-md:w-full"
        >
          <MeetingInformation />
        </div>

        <!-- Meeting Calendar -->
        <div
          class="flex flex-col p-5 max-md:p-3 max-md:w-full"
          v-if="!dateSelected && !meetingCode"
        >
          <MeetingCalendar />
        </div>

        <!-- Attendee Information -->
        <div
          v-if="dateSelected && !attendeeDetails && !meetingCode"
          class="flex flex-col py-5 w-full max-md:w-full"
        >
          <AttendeeInformation />
        </div>

        <!-- Meeting Confirmation -->
        <div
          v-if="dateSelected && attendeeDetails && !meetingCode"
          class="flex flex-col py-5 w-full max-md:w-full"
        >
          <MeetingConfirmation :errorMessage="setErrorMessage" />
        </div>

        <!-- Booked Meeting Details -->
        <div v-if="meetingCode" class="flex flex-col py-5 w-full max-md:w-full">
          <BookedMeetingDetails />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import MeetingInformation from "@/components/expert-meeting/MeetingInformation.vue";
import MeetingCalendar from "@/components/expert-meeting/MeetingCalendar.vue";
import AttendeeInformation from "@/components/expert-meeting/AttendeeInformation.vue";
import { useRoute } from "vue-router";
import { useAttendeeStore } from "@/stores/attendee";
import MeetingConfirmation from "@/components/expert-meeting/MeetingConfirmation.vue";
import BookedMeetingDetails from "@/components/expert-meeting/BookedMeetingDetails.vue";

export default defineComponent({
  name: "BookMeetingView",
  components: {
    MeetingInformation,
    MeetingCalendar,
    AttendeeInformation,
    MeetingConfirmation,
    BookedMeetingDetails,
  },
  setup() {
    const route = useRoute();
    const attendeeStore = useAttendeeStore();
    const companyName = ref("Test Company");
    const errorMessage = ref("");

    const attendeeDetails = computed(() => {
      return attendeeStore.bookMeetingName && attendeeStore.bookMeetingEmail;
      // return false;
    });

    const dateSelected = computed(() => {
      // Check for the presence of the query param (example: "userId")
      return route.query.date && route.query.startTime;
    });

    const setErrorMessage = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 3000);
    };

    const meetingCode = computed(() => {
      console.log("meetingCode: ", route.query.meeting_code);
      return route.query.meeting_code;
    });

    const observer = new MutationObserver(() => {
      const tinyChatBubble = document.querySelector("tiny-chat");

      if (tinyChatBubble) {
        (tinyChatBubble as HTMLElement).style.display = "none";
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return {
      companyName,
      dateSelected,
      attendeeDetails,
      errorMessage,
      setErrorMessage,
      meetingCode,
    };
  },
});
</script>

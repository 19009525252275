<template>
  <article
    class="flex flex-col grow shrink p-2 bg-white rounded-lg min-w-[10.5rem] max-w-[21rem] sm:min-w-[21rem] overflow-hidden"
    :class="{
      'items-center justify-center text-center': isCentered,
      'items-start text-left': !isCentered,
    }"
    :style="{
      fontFamily: fontFamily,
      fontSize: fontSize + 'px',
      height: !isGridUneven ? computedHeight : 'auto',
    }"
  >
    <div
      class="flex gap-2.5"
      :class="{
        'items-start': !isCentered,
        'justify-center items-center': isCentered,
        [profileImageAndNameLayoutClass]: true,
      }"
    >
      <img
        :src="profile.imageUrl"
        :class="profileImageClass"
        alt=""
        class="object-cover shrink-0 w-16 h-16"
      />
      <div
        class="flex flex-col"
        :class="{
          'items-start': !isCentered,
          'justify-center items-center': isCentered,
        }"
      >
        <div
          class="flex flex-row gap-4 items-center"
          :class="{
            'justify-center': isCentered,
          }"
        >
          <span
            class="text-xs font-semibold leading-loose text-zinc-800"
            :style="{
              fontSize: fontSize + 'px',
              fontWeight: boldness,
            }"
            :class="{ 'text-center': isCentered }"
          >
            {{ expertName(profile) }}
          </span>
          <img
            src="@/assets/shared/linkedin-icon.svg"
            alt=""
            class="object-cover shrink-0 w-6 h-6"
            @click="handleLinkedinClick(profile.linkedinUrl)"
          />
        </div>
        <div
          class="flex flex-col items-center w-full max-w-full"
          :class="{
            'justify-center': isCentered,
          }"
        >
          <span
            class="text-xs font-semibold text-zinc-800 break-words overflow-hidden whitespace-normal w-full max-h-[45px] leading-[1.2] line-clamp-2"
            :style="{
              fontSize: fontSize + 'px',
              fontWeight: boldness,
            }"
            :class="{ 'text-center': isCentered }"
          >
            {{ expertTitleAndCompanyName(profile) }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col justify-end w-full gap-3 mt-2"
      :class="{
        'items-center': isCentered,
        'items-start': !isCentered,
        'mt-auto': !isGridUneven,
      }"
    >
      <div class="flex flex-col gap-2 items-end">
        <div class="flex flex-row gap-2 items-center">
          <span class="font-bold">Expertise</span>
          <div class="flex flex-row gap-1 space-x-2">
            <div
              v-for="index in 5"
              :key="index"
              class="w-7 h-2"
              :style="{
                backgroundColor:
                  index <= profile.userProgramProfile[0].expertise
                    ? backgroundColor
                    : '#D1D5DB',
              }"
            ></div>
          </div>
        </div>
        <div class="flex flex-row gap-2 items-center">
          <span class="font-bold">Experience</span>
          <div class="flex flex-row gap-1 space-x-2">
            <div
              v-for="index in 5"
              :key="index"
              class="w-7 h-2"
              :style="{
                backgroundColor:
                  index <= profile.userProgramProfile[0].experience
                    ? backgroundColor
                    : '#D1D5DB',
              }"
            ></div>
          </div>
        </div>
      </div>
      <div v-if="profile.country">
        <span
          class="overflow-hidden gap-1.5 self-stretch px-1.5 py-0.5 my-auto whitespace-nowrap rounded-md bg-slate-50"
          :style="{
            fontWeight: boldness,
          }"
        >
          {{ profile.country }}
        </span>
      </div>
      <div class="w-full" :class="{ 'flex justify-center': isCentered }">
        <button
          @click="handleBookMeeting(profile.uuid)"
          class="overflow-hidden gap-2 w-full py-1.5 pr-2.5 pl-2.5 mt-2.5 font-medium rounded shadow-sm"
          :disabled="!enableBookMeeting"
          :style="{
            backgroundColor: backgroundColor,
            color: textColor,
          }"
        >
          Book A Meeting
        </button>
      </div>
    </div>
  </article>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
} from "vue";
import { PostHog } from "posthog-js";

interface UserProgramProfile {
  expertise: number;
  experience: number;
}

interface Profile {
  id: string;
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  companyName: string;
  imageUrl: string;
  linkedinUrl: string;
  country: string;
  userProgramProfile: UserProgramProfile[]; // An array containing user program profiles
}

export default defineComponent({
  name: "ProfileCard",
  props: {
    profile: {
      type: Object as PropType<Profile>,
      required: true,
    },
    profileImageClass: {
      type: String,
      default: "",
    },
    profileImageAndNameLayoutClass: {
      type: String,
      default: "",
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
    isGridUneven: {
      type: Boolean,
      default: false,
    },
    fontFamily: {
      type: String,
      default: "",
    },
    boldness: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "",
    },
    programUuid: {
      type: String,
      default: "",
    },
    enableBookMeeting: {
      type: Boolean,
      default: false,
    },
    programLogo: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const baseHeight = 230;
    const isSeeMoreModalOpen = ref(false);
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;

    const truncateText = (text: string, maxLength = 180) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    };

    const isTextTruncated = (text: string, maxLength = 180) => {
      return text.length > maxLength;
    };

    const imageUrl = (profile: Profile) => {
      return profile.imageUrl;
    };

    const expertName = (profile: Profile) => {
      return `${profile.firstName} ${profile.lastName}`;
    };

    const handleBookMeeting = (expertId: string) => {
      if (posthog) {
        try {
          console.log("props.profile.email", props.profile.email);
          posthog.capture("Attendee_User_Clicks_In_Directory", {
            email: props.profile.email,
            program_uuid: props.programUuid,
          });
          const url = `${process.env.VUE_APP_BASE_URL}company/expert-meeting/book-meeting?expert=${expertId}&program=${props.programUuid}&logo=${props.programLogo}`;
          window.open(url, "_blank");
        } catch (error) {
          console.error("Error capturing posthog event: ", error);
        }
      } else {
        console.error("PostHog is not initialized");
      }
    };

    const handleLinkedinClick = (linkedinProfile: string) => {
      if (linkedinProfile) {
        if (
          !linkedinProfile.startsWith("http://") &&
          !linkedinProfile.startsWith("https://")
        ) {
          linkedinProfile = `https://${linkedinProfile}`;
        }

        window.open(linkedinProfile, "_blank");
      }
    };

    const handleSeeMore = () => {
      isSeeMoreModalOpen.value = true;
    };

    const computedHeight = computed(() => {
      let extraHeight = 0;
      if (props.profileImageAndNameLayoutClass === "flex-col") {
        extraHeight += 40;
      }
      if (props.fontSize === "12") {
        extraHeight += 20;
      } else if (props.fontSize === "14") {
        extraHeight += 25;
      } else if (props.fontSize === "16") {
        extraHeight += 30;
      } else if (props.fontSize === "18") {
        extraHeight += 35;
      } else if (props.fontSize === "20") {
        extraHeight += 45;
      }
      return `${baseHeight + extraHeight}px`;
    });

    const expertTitleAndCompanyName = (profile: Profile) => {
      if (profile.title && profile.companyName) {
        return `${profile.title}, ${profile.companyName}`;
      } else if (profile.title) {
        return profile.title;
      } else if (profile.companyName) {
        return profile.companyName;
      }
      return "";
    };

    return {
      truncateText,
      imageUrl,
      computedHeight,
      expertName,
      isSeeMoreModalOpen,
      isTextTruncated,
      handleSeeMore,
      handleBookMeeting,
      handleLinkedinClick,
      expertTitleAndCompanyName,
    };
  },
});
</script>

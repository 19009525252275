<template>
  <div class="flex flex-col h-screen">
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <header class="flex flex-col w-full border-b">
      <Navbar :activeTab="2" />
    </header>
    <main class="flex flex-col items-start gap-6 w-full p-6">
      <!-- title -->
      <div class="flex flex-col">
        <span class="text-3xl font-semibold leading-9 text-left text-zinc-800"
          >Analytics</span
        >
      </div>
      <div class="flex justify-center mb-4 w-full">
        <div
          v-if="errorMessage"
          role="alert"
          class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          style="max-height: 80px; overflow-y: auto"
        >
          <div
            class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
          >
            <img
              src="../../assets/shared/failure.svg"
              alt="Error Icon"
              class="w-4 h-4"
            />
          </div>
          <p>{{ errorMessage }}</p>
        </div>
      </div>
      <!-- date filters -->
      <div class="flex w-full">
        <DateFilters :applyFilter="applyFilter" :handleError="handleError" />
      </div>
      <!-- summary -->
      <div class="flex w-full">
        <AnalyticsSummary :totalSpend="totalSpend" />
      </div>
      <!-- graphs -->
      <div class="flex w-full">
        <AnalyticsGraphs
          :key="graphKey"
          :totalExperts="totalExperts"
          :totalBookings="totalBookings"
          :totalDirectoryVisits="totalDirectoryVisits"
          :bookingsByDate="bookingsByDate"
          :expertsByDate="expertsByDate"
          :directoryVisitsByDate="directoryVisitsByDate"
        />
      </div>
      <!-- expert meetings -->
      <div class="flex w-full">
        <ExpertMeetings :bookings="bookings" />
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Navbar from "@/components/dashboard/Navbar.vue";
import DateFilters from "@/components/analytics/DateFilters.vue";
import AnalyticsSummary from "@/components/analytics/AnalyticsSummary.vue";
import AnalyticsGraphs from "@/components/analytics/AnalyticsGraphs.vue";
import ExpertMeetings from "@/components/analytics/ExpertMeetings.vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { AnalyticsTotalBookings } from "@/types/analytics-types";

const GET_COMPANY_ANALYTICS = gql`
  query getCompanyAnalytics(
    $startDate: String!
    $endDate: String!
    $groupBy: String
  ) {
    getCompanyAnalytics(
      startDate: $startDate
      endDate: $endDate
      groupBy: $groupBy
    ) {
      totalSpend
      totalExperts
      totalBookings
      totalDirectoryVisits
      bookingsByDate {
        date
        total
      }
      expertsByDate {
        date
        total
      }
      directoryVisitsByDate {
        date
        total
      }
      bookings {
        startTime
        status
        programPayout
        fees
        attendee {
          name
          email
        }
        user {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export default defineComponent({
  name: "AnalyticsView",
  components: {
    Navbar,
    DateFilters,
    AnalyticsSummary,
    AnalyticsGraphs,
    ExpertMeetings,
  },
  setup() {
    const isLoading = ref(false);
    const graphKey = ref(0);
    const errorMessage = ref("");
    const totalSpend = ref(0.0);
    const totalExperts = ref(0);
    const totalBookings = ref(0);
    const totalDirectoryVisits = ref(0);
    const bookingsByDate = ref([]);
    const expertsByDate = ref([]);
    const directoryVisitsByDate = ref([]);
    const bookings = ref<AnalyticsTotalBookings["bookings"]>();
    const analyticsGraphsRef = ref<InstanceType<typeof AnalyticsGraphs> | null>(
      null
    );

    const fetchData = (startDate: Date, endDate: Date, type: string) => {
      endDate = addOneDay(endDate);
      isLoading.value = true;
      const { onResult, onError } = useQuery(
        GET_COMPANY_ANALYTICS,
        {
          startDate: convertToISO8601(startDate),
          endDate: convertToISO8601(endDate),
          groupBy: type,
        },
        { fetchPolicy: "network-only", errorPolicy: "all" }
      );

      onResult((result) => {
        if (result) {
          totalSpend.value =
            result?.data?.getCompanyAnalytics?.totalSpend || 0.0;
          totalExperts.value =
            result?.data?.getCompanyAnalytics?.totalExperts || 0;
          totalBookings.value =
            result?.data?.getCompanyAnalytics?.totalBookings || 0;
          totalDirectoryVisits.value =
            result?.data?.getCompanyAnalytics?.totalDirectoryVisits || 0;
          bookingsByDate.value =
            result?.data?.getCompanyAnalytics?.bookingsByDate || [];
          expertsByDate.value =
            result?.data?.getCompanyAnalytics?.expertsByDate || [];
          directoryVisitsByDate.value =
            result?.data?.getCompanyAnalytics?.directoryVisitsByDate || [];
          bookings.value = result?.data?.getCompanyAnalytics?.bookings || [];

          isLoading.value = false;
          graphKey.value++;
        }
      });

      onError((error) => {
        if (error) {
          handleError(error.message);
          isLoading.value = false;
        }
      });
    };

    const handleError = (error: string) => {
      errorMessage.value = error;
      setTimeout(() => {
        errorMessage.value = "";
      }, 5000);
    };

    const addOneDay = (date: Date): Date => {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    };

    const convertToISO8601 = (dateString: Date): string => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const isoDate = `${year}-${month}-${day}`;

      return isoDate;
    };

    const applyFilter = (startDate: Date, endDate: Date, type: string) => {
      fetchData(startDate, endDate, type);
    };

    return {
      isLoading,
      errorMessage,
      totalSpend,
      totalExperts,
      totalBookings,
      bookingsByDate,
      expertsByDate,
      directoryVisitsByDate,
      totalDirectoryVisits,
      bookings,
      applyFilter,
      handleError,
      analyticsGraphsRef,
      graphKey,
    };
  },
});
</script>

<template>
  <main class="bg-white min-h-screen flex flex-col">
    <div class="flex flex-grow max-md:flex-col">
      <aside
        class="flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple"
      >
        <div class="flex flex-col mt-14 flex-grow">
          <div class="flex flex-col ml-10 text-left">
            <!-- First Image -->
            <span class="text-white text-3xl font-semibold leading-9">
              Get Started <br />
            </span>
          </div>

          <StepsList :currentStep="0" />
        </div>
      </aside>
      <section
        class="flex flex-col w-4/5 justify-center mt-10 pt-6 pb-1 max-md:w-full"
      >
        <div class="flex justify-center mb-4">
          <div
            v-if="errorMessage"
            role="alert"
            class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
            style="max-height: 80px; overflow-y: auto"
          >
            <!-- Icon Container -->
            <div
              class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
            >
              <!-- Custom Image Icon -->
              <img
                src="../../assets/shared/failure.svg"
                alt="Error Icon"
                class="w-4 h-4"
              />
            </div>
            <p>{{ errorMessage }}</p>
          </div>
        </div>
        <div
          class="flex flex-col w-1/3 mx-auto justify-center max-md:mt-4 max-md:w-full"
        >
          <h1 class="text-3xl font-semibold leading-9 text-left text-zinc-800">
            Expert Program Details
          </h1>
          <p class="mt-3 text-base leading-6 text-left text-neutral-700">
            Create your account to get started
          </p>
          <form id="expertProgramForm">
            <div class="relative mx-auto">
              <!-- SoftwareSearch Section -->
              <div
                class="relative z-20 flex gap-5 justify-between mt-8 text-left"
              >
                <div class="flex flex-col flex-grow basis-1/2">
                  <BaseLabel
                    forAttr="Software"
                    text="Software"
                    class="text-sm font-medium leading-5 text-zinc-800"
                  />
                  <span class="text-xs"> Add your software </span>
                  <!-- SoftwareSearch has to stack above the rest -->
                  <SoftwareSearch @selectProgram="selectProgram" />
                </div>
              </div>

              <!-- Summary Section -->
              <div class="relative mt-3 text-left z-10">
                <label
                  for="about"
                  class="self-start mb-2 mt-4 text-sm font-medium leading-none text-gray-700"
                >
                  About The Program
                </label>
                <textarea
                  ref="aboutTextarea"
                  id="about"
                  v-model="about"
                  :rows="rows"
                  maxlength="1057"
                  @input="handleAboutInput"
                  required
                  placeholder=""
                  class="resize-none w-full p-2 text-base text-gray-500 bg-white border rounded-md shadow-sm focus:outline-none"
                  :class="{
                    'border-red-500': aboutError,
                    'border-gray-300': !aboutError,
                  }"
                ></textarea>
                <span
                  class="mt-2 text-sm leading-none ml-3 px-1 text-left max-md:max-w-full"
                  :class="{
                    'text-red-500': aboutError,
                    'text-gray-500': !aboutError,
                  }"
                  >{{ about.length }}/1056</span
                >
              </div>

              <div class="flex flex-col mt-4">
                <BaseLabel
                  forAttr="settings"
                  text="Settings"
                  class="text-lg font-medium leading-5 text-zinc-800 text-left"
                />
              </div>

              <!-- Payout and Fee Section -->
              <div class="relative mt-4 text-left z-10">
                <div class="flex flex-col 2xl:flex-row gap-2">
                  <!-- First Input Field with Label -->
                  <div class="w-full 2xl:w-3/5">
                    <BaseLabel
                      forAttr="payout"
                      text="Expert Payout per Meeting"
                      class="text-sm font-medium leading-5 text-zinc-800"
                    />
                    <div>
                      <CurrencyInput
                        v-model="payout"
                        leadingText="$"
                        trailingText="USD"
                        placeholder="100"
                      />
                    </div>
                  </div>

                  <!-- Second Input Field with Label -->
                  <div class="w-full 2xl:w-2/5">
                    <BaseLabel
                      forAttr="fee"
                      text="SlashExperts Fee (35%)"
                      class="text-sm font-medium leading-5 text-zinc-800"
                    />
                    <span
                      id="fee"
                      class="px-3.5 py-2.5 text-sm leading-5 text-gray-500 whitespace-nowrap bg-gray-100 rounded-md shadow-sm w-full block"
                    >
                      $ {{ payout * 0.35 }}
                    </span>
                  </div>
                </div>
                <span class="text-xs text-gray-500"
                  >Total Cost Per Meeting: ${{ payout + payout * 0.35 }}</span
                >
              </div>

              <!-- meeting duration less than 5 minutes -->
              <div class="relative flex flex-col mt-6 text-zinc-800 gap-2">
                <BaseLabel
                  forAttr="meetingDuration"
                  text="Payout only if meeting duration is more than 5 minutes."
                  class="text-sm font-medium leading-5 text-zinc-800 text-left"
                />
                <div class="flex flex-col justify-center">
                  <BaseButton
                    type="button"
                    class="flex items-center w-12 h-6 rounded-xl transition-colors duration-300"
                    :class="{
                      'bg-violet-600': shortMeetingDuration,
                      'bg-gray-200': !shortMeetingDuration,
                    }"
                    @click="toggleShortMeetingDuration()"
                    aria-label="Toggle button"
                  >
                    <span
                      class="flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform"
                      :class="{
                        'translate-x-6': shortMeetingDuration,
                        'translate-x-0.5': !shortMeetingDuration,
                      }"
                    ></span>
                  </BaseButton>
                </div>
              </div>

              <!-- company linkedin url -->
              <div class="relative flex flex-col mt-6 text-zinc-800">
                <BaseLabel
                  forAttr="linkedinURL"
                  text="Your Company Linkedin URL"
                  class="text-sm font-medium leading-5 text-zinc-800 text-left"
                />
                <div class="relative">
                  <input
                    id="linkedinUrl"
                    type="text"
                    v-model="linkedinUrl"
                    placeholder="https://www.linkedin.com/company/companyname"
                    class="px-3.5 py-2.5 mt-1 w-full bg-white rounded-md shadow-sm pr-1 border border-gray-300 border-solid"
                    required
                  />
                </div>
              </div>

              <!-- Goals Section -->
              <div
                class="relative flex flex-col justify-start mt-6 text-base font-medium leading-6 z-10"
              >
                <BaseLabel
                  forAttr="Goals"
                  text="Goals & Use Cases"
                  class="text-lg font-medium leading-5 text-zinc-800 text-left"
                />
                <GoalsCheckboxList
                  v-model:convertWebsiteVisitors="convertWebsiteVisitors"
                  v-model:generateIntentSignals="generateIntentSignals"
                  v-model:enableSales="enableSales"
                  v-model:engageNewCustomer="engageNewCustomer"
                  v-model:driveConversion="driveConversion"
                  v-model:sdrSequenceStep="sdrSequenceStep"
                  v-model:lateStageCustomerReferences="
                    lateStageCustomerReferences
                  "
                />
              </div>
            </div>
          </form>
        </div>
        <FooterSection :isDisabled="isDisabled" @next="goNext" />
      </section>
    </div>
  </main>
</template>

<script lang="ts">
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import FooterSection from "@/components/expert-program/FooterSection.vue";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import GoalsCheckboxList from "@/components/expert-program/program-details/GoalsCheckboxList.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import SoftwareSearch from "@/components/expert-program/program-details/SoftwareSearch.vue";
import { Card } from "@/types/expert-program-types";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import StepsList from "@/components/expert-program/StepsList.vue";
import { CreateExpertProgram } from "@/types/user-store-types";
import router from "@/router";
import { useUserStore } from "@/stores/user";
import { PostHog } from "posthog-js";

const UPDATE_PROGRAM = gql`
  mutation updateProgram($input: UpdateProgramInput!) {
    updateProgram(input: $input) {
      program {
        id
      }
      errors
    }
  }
`;
export default defineComponent({
  name: "AddProgramDetails",
  components: {
    GoalsCheckboxList,
    BaseLabel,
    CurrencyInput,
    SoftwareSearch,
    FooterSection,
    StepsList,
    BaseButton,
  },
  setup() {
    const software = ref("");
    const about = ref("");
    const payout = ref(100);
    const rows = ref(8);
    const companyName = ref("");
    const email = ref("");
    const title = ref("");
    const linkedinUrl = ref("");
    const convertWebsiteVisitors = ref(false);
    const generateIntentSignals = ref(false);
    const enableSales = ref(false);
    const driveConversion = ref(false);
    const lateStageCustomerReferences = ref(false);
    const sdrSequenceStep = ref(false);
    const engageNewCustomer = ref(false);
    const companyWebsite = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const agreedToTerms = ref(false);
    const showPassword = ref(false);
    const showConfirmPassword = ref(false);
    const errorMessage = ref<string | null>(null);
    const tempDisable = ref(true);
    const isDisabled = ref(false);
    const selectedProgram = ref<Card | null>(null);
    const aboutError = ref(false);
    const shortMeetingDuration = ref(false);
    const userStore = useUserStore();
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;

    const {
      mutate: updateProgram,
      onDone: updateProgramDone,
      onError: updateProgramError,
    } = useMutation(UPDATE_PROGRAM);

    const goNext = () => {
      errorMessage.value = "";
      const valid = validateInput();
      if (valid) {
        isDisabled.value = true;
        callUpdateProgram();
      }
    };

    const validateInput = () => {
      if (!selectedProgram.value) {
        errorMessage.value = "Please select a software";
        scrollToTop();
        return false;
      }
      if (!payout.value) {
        errorMessage.value = "Please provide a value for payout";
        scrollToTop();
        return false;
      }

      if (!linkedinUrlValid()) {
        scrollToTop();
        return false;
      }

      return true;
    };

    const callUpdateProgram = () => {
      updateProgram({
        input: {
          programParams: {
            id: selectedProgram?.value?.id,
            name: selectedProgram?.value?.name,
            website: selectedProgram?.value?.website,
            description: about.value,
            payout: payout.value,
            payoutOnlyCallDurationMoreThan5: shortMeetingDuration.value,
            linkedinCompanyUrl: linkedinUrl.value,
            convertWebsiteVisitors: convertWebsiteVisitors.value,
            generateIntentSignals: generateIntentSignals.value,
            enableSales: enableSales.value,
            driveConversion: driveConversion.value,
            lateStageCustomerReferences: lateStageCustomerReferences.value,
            sdrSequenceStep: sdrSequenceStep.value,
            engageNewCustomer: engageNewCustomer.value,
          },
        },
      });
      updateProgramDone((response) => {
        if (response) {
          isDisabled.value = false;
          if (response.data.updateProgram.errors.length > 0) {
            errorMessage.value = response.data.updateProgram.errors.join(".");
            scrollToTop();
          } else {
            storeProgramDetails();
          }
        }
      });
      updateProgramError((error: ApolloError) => {
        isDisabled.value = false;
        errorMessage.value = error.message;
        scrollToTop();
        console.error("Submission failed:", error.message);
      });
    };

    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    const linkedinUrlValid = (): boolean => {
      try {
        let url = linkedinUrl.value;
        if (!url.toLowerCase().includes("linkedin.com/company/")) {
          errorMessage.value =
            "Please include 'linkedin.com/company/' in the linkedin URL.";
          return false;
        }

        url = url.endsWith("/") ? url.slice(0, -1) : url;
        const parts = url.split("/company/");
        if (parts.length > 1 && /^[a-zA-Z0-9-]+$/.test(parts[1])) {
          return true;
        } else {
          errorMessage.value = "Invalid LinkedIn profile name.";
          return false;
        }
      } catch (error) {
        errorMessage.value = "Invalid LinkedIn URL format.";
        return false;
      }
    };

    const selectProgram = (program: Card) => {
      selectedProgram.value = program;
      about.value = `Welcome to the ${program.name} Expert Program. Our Expert Program is a structured initiative designed to identify, engage, and leverage the knowledge and expertise of our customers, partners, and advocates. Experts will have the opportunity to meet with prospective buyers or customers and share your product and industry insights and experience. We're excited to have you join our Expert Program!`;
    };

    const notifyPosthog = () => {
      if (posthog) {
        posthog.capture("Company_User_Completes_Program_Details", {
          email: userStore.email,
          software_name: selectedProgram.value?.name,
          about_program: about.value,
          payout: payout.value,
          short_meeting_duration: shortMeetingDuration.value,
          linkedin_url: linkedinUrl.value,
          convert_website_visitors: convertWebsiteVisitors.value,
          generate_intent_signals: generateIntentSignals.value,
          enable_sales: enableSales.value,
          drive_conversion: driveConversion.value,
          late_stage_customer_references: lateStageCustomerReferences.value,
          sdr_sequence_step: sdrSequenceStep.value,
          engage_new_customer: engageNewCustomer.value,
        });
      }
    };

    const storeProgramDetails = () => {
      const expertProgram: CreateExpertProgram = {
        id: selectedProgram.value?.id ?? "",
        uuid: selectedProgram.value?.uuid ?? "",
        name: selectedProgram.value?.name ?? "",
        website: selectedProgram.value?.website ?? "",
        companyId: "",
        directoryCreated: false,
        widgetCreated: false,
        salesforceSynced: false,
        directoryButtonStyles: {
          backgroundColor: "",
          textColor: "",
          boldness: "",
          fontFamily: "",
          fontSize: "",
        },
        createdWidgetIds: [],
      };
      userStore.setCreateExpertProgram(expertProgram);
      notifyPosthog();
      if (userStore.createExpertProgram) {
        router.push({ name: "AddAccountBalance" });
      } else {
        errorMessage.value = "Failed to store program details.";
      }
    };

    const calculateRows = () => {
      const screenWidth = window.innerWidth;

      // Adjust rows based on screen width
      if (screenWidth < 600) {
        rows.value = 22; // For small screens (mobile)
      } else if (screenWidth < 900) {
        rows.value = 18; // For medium screens (tablet)
      } else if (screenWidth < 1260) {
        rows.value = 14; // For medium screens (tablet)
      } else {
        rows.value = 10; // For large screens (desktop)
      }

      console.log("Rows updated to:", rows.value, "Screen width:", screenWidth);
    };

    const toggleShortMeetingDuration = () => {
      shortMeetingDuration.value = !shortMeetingDuration.value;
    };

    const handleAboutInput = () => {
      if (about.value.length > 1056) {
        aboutError.value = true;
        about.value = about.value.slice(0, 1056);
        setTimeout(() => {
          aboutError.value = false;
        }, 2000);
      }
    };

    onMounted(() => {
      scrollToTop();
      calculateRows();

      window.addEventListener("resize", calculateRows);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", calculateRows);
    });

    return {
      software,
      payout,
      about,
      linkedinUrl,
      companyName,
      email,
      companyWebsite,
      convertWebsiteVisitors,
      generateIntentSignals,
      enableSales,
      driveConversion,
      isDisabled,
      goNext,
      lateStageCustomerReferences,
      sdrSequenceStep,
      engageNewCustomer,
      selectProgram,
      title,
      password,
      confirmPassword,
      agreedToTerms,
      showPassword,
      showConfirmPassword,
      errorMessage,
      tempDisable,
      rows,
      shortMeetingDuration,
      toggleShortMeetingDuration,
      handleAboutInput,
      aboutError,
    };
  },
});
</script>

<template>
  <div class="flex flex-col z-20 gap-5 justify-between mt-2 text-left">
    <!-- error div -->
    <div class="flex justify-center mb-4">
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
        style="max-height: 80px; overflow-y: auto"
      >
        <!-- Icon Container -->
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <!-- Custom Image Icon -->
          <img
            src="@/assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>
    </div>
    <!-- Account Balance Amount Section -->
    <div class="flex flex-col gap-2 flex-grow basis-1/2">
      <div class="flex flex-col gap-1">
        <BaseLabel
          forAttr="currentAccountBalance"
          text="Available Balance"
          class="text-sm font-medium leading-5 text-zinc-800 text-left"
        />
        <span class="text-lg">$ {{ availableBalance.toFixed(2) }}</span>
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          forAttr="accountBalance"
          text="Add Balance"
          class="text-sm font-medium leading-5 text-zinc-800 text-left"
        />
        <span class="text-xs">
          Enter the amount to add to your total balance right now
        </span>

        <div>
          <CurrencyInput
            v-model="accountBalance"
            leadingText="$"
            trailingText="USD"
            placeholder="100"
          />
        </div>
      </div>
    </div>

    <!-- Auto top-up section -->
    <div class="flex flex-col border b-1 p-6">
      <div class="flex flex-row justify-between items-center">
        <h2 class="font-semibold leading-9 text-left text-zinc-800">
          Auto Top-up
        </h2>

        <button
          @click="toggleAutoTopup"
          :class="[
            isAutoTopupEnabled ? 'bg-primary-purple' : 'bg-gray-200',
            'flex items-center h-6 w-11 rounded-full p-1 transition-colors ease-in-out duration-300',
          ]"
        >
          <span
            :class="[
              isAutoTopupEnabled ? 'translate-x-5' : 'translate-x-0',
              'block w-4 h-4 transform bg-white rounded-full transition-transform ease-in-out duration-300',
            ]"
          ></span>
        </button>
      </div>

      <div class="flex flex-col my-3">
        <span class="text-sm leading-tight"
          >Enter the amount you want to automatically top up your account.</span
        >
      </div>

      <div
        v-if="isAutoTopupEnabled"
        class="flex flex-wrap items-center my-3 gap-4 justify-start overflow-x-hidden"
      >
        <div class="flex flex-col sm:flex-row flex-1">
          <span class="text-sm mr-2 font-bold"> If amount goes below</span>
          <div class="flex-1 w-1/2 sm:w-2/3 md:w-full">
            <CurrencyInput
              v-model="topUpLimit"
              leadingText="$"
              trailingText="USD"
              placeholder="100"
            />
          </div>
        </div>
        <div class="flex flex-col sm:flex-row flex-1">
          <span class="text-sm mx-2 font-bold"> Auto top-up for</span>
          <div class="flex-1 w-1/2 sm:w-2/3 md:w-full">
            <CurrencyInput
              v-model="topUpAmount"
              leadingText="$"
              trailingText="USD"
              placeholder="100"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- use current payment method or add new? -->
    <div class="flex flex-wrap border b-1 p-6 rounded-md gap-2">
      <div class="flex flex-col gap-2">
        <div class="flex flex-col">
          <BaseButton
            type="button"
            class="text-sm font-medium leading-5 text-center rounded-md p-2"
            :class="{
              'bg-primary-purple text-white': isPaymentMethodCurrent,
              'bg-gray-200 text-zinc-800': !isPaymentMethodCurrent,
            }"
            @click="setPaymentMethodToCurrent"
            >Use current payment method</BaseButton
          >
        </div>
        <div
          v-if="isPaymentMethodCurrent"
          class="flex flex-col text-xs font-medium leading-5 text-zinc-800 text-left"
        >
          <div
            class="flex flex-col"
            v-if="paymentMethodSource === 'credit_card'"
          >
            <span>Card Number: {{ paymentMethodCardNumber }}</span>
            <span>Expiration Date: {{ paymentMethodExpirationDate }}</span>
          </div>
          <div v-else>
            <span
              >Invoice Recipient Email:
              {{ paymentMethodInvoiceRecipientEmail }}</span
            >
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <span class="text-sm font-medium leading-5 p-2 text-zinc-800 text-left"
          >OR</span
        >
      </div>
      <div class="flex flex-col">
        <BaseButton
          type="button"
          class="text-sm font-medium leading-5 rounded-md text-center p-2"
          :class="{
            'bg-primary-purple text-white': !isPaymentMethodCurrent,
            'bg-gray-200 text-zinc-800': isPaymentMethodCurrent,
          }"
          @click="setPaymentMethodToNew"
          >Add New Payment Method</BaseButton
        >
      </div>
    </div>
    <!-- billing method tab control -->
    <div class="flex flex-col" v-if="!isPaymentMethodCurrent">
      <div class="flex flex-col font-bold text-sm">
        <span>Add Billing Method</span>
        <div class="flex flex-row p-3 bg-gray-200 rounded-md">
          <div class="flex flex-row w-1/2 rounded-md">
            <div
              class="flex flex-row p-3 w-full justify-center items-center rounded-md"
              :class="{
                'bg-primary-purple text-white': isPaymentMethodCard,
                'text-secondary-gray': !isPaymentMethodCard,
              }"
              @click="setPaymentMethodToCard"
            >
              <div
                v-html="CARD_ICON"
                class="mr-2"
                :class="{
                  'text-white': isPaymentMethodCard,
                  'text-secondary-gray': !isPaymentMethodCard,
                }"
              ></div>
              Card
            </div>
          </div>
          <div class="flex flex-row w-1/2">
            <div
              class="flex flex-row p-3 w-full justify-center items-center rounded-md"
              :class="{
                'bg-primary-purple text-white': !isPaymentMethodCard,
                'text-secondary-gray': isPaymentMethodCard,
              }"
              @click="setPaymentMethodToInvoice"
            >
              <div
                v-html="DOCUMENT_DOLLAR_ICON"
                class="mr-2"
                :class="{
                  'text-white': !isPaymentMethodCard,
                  'text-secondary-gray': isPaymentMethodCard,
                }"
              ></div>
              Invoice
            </div>
          </div>
        </div>
      </div>

      <!-- billing method section -->
      <div v-if="isPaymentMethodCard">
        <StripeCardFields
          @paymentMethodId="handlePaymentMethodId"
          @error="handleError"
        />
      </div>
      <div v-show="!isPaymentMethodCard">
        <BaseLabel
          forAttr="invoiceEmail"
          text="Invoice Recipient Email"
          class="text-sm font-medium leading-5 text-zinc-800 text-left"
        />
        <BaseInput
          type="email"
          id="invoiceEmail"
          v-model="invoiceEmail"
          placeholder="you@example.com"
          required
          className="px-3.5 py-2.5 mt-1 text-gray-500 bg-white rounded-md border border-solid shadow-sm w-full"
        />
      </div>
    </div>

    <div class="flex flex-row justify-end">
      <div class="flex flex-row gap-2">
        <span
          class="flex flex-col justify-center items-center text-primary-purple"
          v-if="isLoadingAccountBalance"
          >Updating....</span
        >
        <BaseButton
          type="button"
          class="flex flex-col justify-center items-center p-2 w-full text-white bg-primary-purple rounded-lg text-sm font-medium leading-none text-center"
          :disabled="isLoadingAccountBalance"
          @click="cancel"
          >Cancel</BaseButton
        >
        <BaseButton
          type="button"
          class="flex flex-col justify-center items-center p-2 w-full text-white bg-primary-purple rounded-lg text-sm font-medium leading-none text-center"
          @click="update"
          :disabled="isLoadingAccountBalance"
          >Update</BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import StripeCardFields from "@/components/expert-program/account-balance/StripeCardFields.vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { useUserStore } from "@/stores/user";
import {
  CARD_ICON,
  DOCUMENT_DOLLAR_ICON,
} from "@/assets/svg/shared/svg-constants";

const CREATE_ACCOUNT_BALANCE = gql`
  mutation createAccountBalance($input: CreateAccountBalanceInput!) {
    createAccountBalance(input: $input) {
      errors
      message
      success
    }
  }
`;

export default defineComponent({
  name: "AddAccountBalance",
  components: {
    BaseInput,
    BaseLabel,
    BaseButton,
    CurrencyInput,
    StripeCardFields,
  },
  props: {
    availableBalance: {
      type: Number,
      required: true,
    },
    autoTopup: {
      type: Boolean,
      required: true,
    },
    autoRechargeAmount: {
      type: Number,
      required: true,
    },
    lowBalanceAmount: {
      type: Number,
      required: true,
    },
    paymentMethodExpirationDate: {
      type: String,
      required: false,
    },
    paymentMethodCardNumber: {
      type: String,
      required: false,
    },
    paymentMethodInvoiceRecipientEmail: {
      type: String,
      required: false,
    },
    paymentMethodSource: {
      type: String,
      required: false,
    },
    paymentMethodId: {
      type: String,
      required: false,
    },
    accountBalanceId: {
      type: String,
      required: true,
    },
    cancel: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  // emits: ["updateAccountBalance"],
  setup(props, { emit }) {
    const isPaymentMethodCard = ref(true);
    const topUpLimit = ref(
      props.lowBalanceAmount === 0 ? null : props.lowBalanceAmount
    );
    const topUpAmount = ref(
      props.autoRechargeAmount === 0 ? null : props.autoRechargeAmount
    );
    const isAutoTopupEnabled = ref(props.autoTopup);
    const accountBalance = ref();
    const invoiceEmail = ref("");
    const errorMessage = ref<string | null>(null);
    const isDisabled = ref(false);
    const isPaymentMethodCurrent = ref(true);
    const userStore = useUserStore();

    const {
      mutate: createAccountBalance,
      onDone: createAccountBalanceDone,
      onError: createAccountBalanceError,
      loading: isLoadingAccountBalance,
    } = useMutation(CREATE_ACCOUNT_BALANCE);

    // const cancel = () => {
    //   console.log("Back clicked ");
    //   // router.push({ name: "CreateExpertProgram" });
    // };

    const update = () => {
      isDisabled.value = true;
      errorMessage.value = "";
      const valid = validate();
      console.log("Validate value is ", valid);
      if (valid === true) {
        if (!isPaymentMethodCurrent.value && isPaymentMethodCard.value) {
          generatePaymentMethodId();
        } else {
          callAccountBalanceMutation("");
        }
      } else {
        scrollToTop();
        isDisabled.value = false;
      }
    };

    const generatePaymentMethodId = async () => {
      document.dispatchEvent(new Event("generatePaymentMethodId"));
    };

    const handlePaymentMethodId = (paymentMethodId: string) => {
      console.log("Received Payment Method ID:", paymentMethodId);
      callAccountBalanceMutation(paymentMethodId);
    };

    const handleError = (error: string) => {
      console.log("Received Payment error:", error);
      errorMessage.value = error;
      isDisabled.value = false;
      scrollToTop();
    };

    const validate = () => {
      if (isAutoTopupEnabled.value) {
        if (
          !topUpLimit.value ||
          !topUpAmount.value ||
          topUpLimit.value <= 0 ||
          topUpAmount.value <= 0
        ) {
          errorMessage.value =
            "Auto topup limit and amount must be greater than 0";
          return false;
        }
      }

      if (!isPaymentMethodCard.value) {
        const emailField =
          document.querySelector<HTMLInputElement>("#invoiceEmail");
        if (emailField) {
          if (!emailField.checkValidity()) {
            emailField.reportValidity();
            return false;
          } else {
            return true;
          }
        }
      }

      return true;
    };

    const callAccountBalanceMutation = async (paymentMethodId: string) => {
      // console.log("Account balance ID: ", props.accountBalanceId);
      // console.log("Account balance value: ", accountBalance.value);
      createAccountBalance({
        input: {
          accountBalanceParams: {
            id: props.accountBalanceId,
            availableBalance: accountBalance.value || 0,
            currency: "usd",
            isDefault: true,
            invoiceRecipient: invoiceRecipient(),
            autoTopup: isAutoTopupEnabled.value,
            autoRechargeAmount: topUpAmount.value,
            lowBalanceAmount: topUpLimit.value,
            paymentMethod: {
              source: paymentMethodSource(),
              isActive: true,
              paymentMethodId: setPaymentMethodId(paymentMethodId),
            },
          },
        },
      });

      createAccountBalanceDone(async (response) => {
        if (response) {
          isDisabled.value = false;
          const data = response.data.createAccountBalance;
          if (data.success) {
            console.log("Create account balance success");
            userStore.setUpdateAccountBalance(true);
            emit("refetchAccountBalance");
          } else {
            errorMessage.value = data.errors.join(".");
          }
        }
      });

      createAccountBalanceError((error: ApolloError) => {
        errorMessage.value = error.message;
        isDisabled.value = false;
        scrollToTop();
        console.log("Create account balance error: ", error.message);
      });
    };

    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    const invoiceRecipient = () => {
      if (!isPaymentMethodCard.value) {
        return invoiceEmail.value;
      }
    };

    const toggleAutoTopup = () => {
      isAutoTopupEnabled.value = !isAutoTopupEnabled.value;
      if (!isAutoTopupEnabled.value) {
        topUpAmount.value = 0;
        topUpLimit.value = 0;
      }
    };

    const setPaymentMethodToCard = () => {
      isPaymentMethodCard.value = true;
    };

    const setPaymentMethodToInvoice = () => {
      isPaymentMethodCard.value = false;
    };

    const setPaymentMethodToCurrent = () => {
      isPaymentMethodCurrent.value = true;
    };

    const setPaymentMethodToNew = () => {
      isPaymentMethodCurrent.value = false;
    };

    const paymentMethodSource = () => {
      if (isPaymentMethodCurrent.value) {
        return props.paymentMethodSource;
      } else {
        if (isPaymentMethodCard.value) {
          return "credit_card";
        } else {
          return "invoice";
        }
      }
    };

    const setPaymentMethodId = (paymentMethodId: string) => {
      if (isPaymentMethodCurrent.value) {
        return props.paymentMethodId;
      }
      return paymentMethodId;
    };

    return {
      accountBalance,
      isDisabled,
      toggleAutoTopup,
      isAutoTopupEnabled,
      topUpLimit,
      topUpAmount,
      isPaymentMethodCard,
      handleError,
      handlePaymentMethodId,
      setPaymentMethodToCard,
      setPaymentMethodToInvoice,
      invoiceEmail,
      errorMessage,
      CARD_ICON,
      DOCUMENT_DOLLAR_ICON,
      // cancel,
      update,
      setPaymentMethodToCurrent,
      setPaymentMethodToNew,
      isPaymentMethodCurrent,
      isLoadingAccountBalance,
    };
  },
});
</script>

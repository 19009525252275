<template>
  <div class="flex flex-col gap-4 w-full">
    <div class="flex flex-col text-left">
      <span
        class="text-gray-900 text-left font-sans text-lg font-semibold leading-9"
        >Expert Meetings</span
      >
    </div>
    <div class="flex flex-col overflow-x-auto">
      <table class="min-w-full table-auto border border-gray-300 mb-8">
        <thead>
          <tr class="border-b border-gray-300">
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Expert
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Attendee
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Meeting Date
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Status
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Payout
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Deal / Opportunity Amount
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in bookings"
            :key="index"
            class="hover:bg-gray-50 text-left"
          >
            <td
              class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
            >
              <div class="flex flex-col">
                <span class="truncate">{{
                  row?.user?.firstName + " " + row?.user?.lastName
                }}</span>
                <span class="truncate">{{ row?.user?.email }}</span>
              </div>
            </td>
            <td
              class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
            >
              <div class="flex flex-col">
                <span class="truncate">{{ row?.attendee?.name }}</span>
                <span class="truncate">{{ row?.attendee?.email }}</span>
              </div>
            </td>
            <td
              class="px-4 py-2 font-medium text-xs leading-4 border-b text-gray-900"
            >
              <span class="truncate">{{ convertToDate(row.startTime) }}</span>
            </td>
            <td
              class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
            >
              <div class="flex flex-col">
                <span class="truncate"> {{ row?.status.toUpperCase() }}</span>
                <span class="truncate" v-if="row.status === 'incomplete'"
                  >(Under 5 mins, no payout)</span
                >
              </div>
            </td>
            <td
              class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
            >
              <div class="relative group">
                ${{ bookingPayout(row?.programPayout, row?.status) }}
                <div
                  class="absolute invisible whitespace-nowrap opacity-0 group-hover:visible group-hover:opacity-100 transition bg-gray-800 text-white text-xs rounded px-2 py-1 left-1/2 transform -translate-x-1/2 bottom-full mb-1 z-10"
                >
                  Fee: ${{ fees(row?.programPayout, row?.fees, row?.status) }}
                </div>
              </div>
            </td>
            <td
              class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
            >
              <div class="truncate">Coming Soon</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { AnalyticsTotalBookings } from "@/types/analytics-types";

export default defineComponent({
  name: "ExpertMeetings",
  props: {
    bookings: {
      type: Array as PropType<AnalyticsTotalBookings["bookings"]>,
      required: false,
    },
  },
  setup() {
    const convertToDate = (date: string) => {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const bookingPayout = (payout: number, status: string) => {
      if (status === "completed") {
        return payout != null ? payout.toFixed(2) : 0;
      }
      return 0;
    };

    const fees = (payout: number, fees: number, status: string) => {
      if (payout > 0 && fees > 0 && status === "completed") {
        return ((payout * fees) / 100).toFixed(2);
      }
      return 0;
    };

    return {
      convertToDate,
      bookingPayout,
      fees,
    };
  },
});
</script>

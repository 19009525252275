import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/shared/user.svg'


const _hoisted_1 = { class: "flex flex-col w-4/5 max:md-w-1/2 space-y-4" }
const _hoisted_2 = { class: "flex flex-wrap justify-between gap-6 py-6 border-b border-gray-200" }
const _hoisted_3 = { class: "flex flex-col flex-1 gap-2 text-left" }
const _hoisted_4 = { class: "flex gap-2 w-full" }
const _hoisted_5 = { class: "flex-1" }
const _hoisted_6 = { class: "flex-1" }
const _hoisted_7 = { class: "flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200" }
const _hoisted_8 = { class: "flex flex-col flex-1 text-left" }
const _hoisted_9 = { class: "flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200" }
const _hoisted_10 = { class: "flex flex-wrap flex-1 text-left gap-4 items-center" }
const _hoisted_11 = { class: "relative w-12 h-12 overflow-hidden rounded-full flex items-center justify-center" }
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 1,
  src: _imports_0,
  alt: "Default profile picture",
  class: "w-full h-full object-cover",
  loading: "lazy"
}
const _hoisted_14 = { class: "flex flex-col" }
const _hoisted_15 = { class: "flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200" }
const _hoisted_16 = { class: "flex flex-col flex-1 text-left" }
const _hoisted_17 = { class: "flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200" }
const _hoisted_18 = { class: "flex flex-col flex-1 text-left" }
const _hoisted_19 = { class: "flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200" }
const _hoisted_20 = { class: "flex flex-col flex-1 text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex flex-col w-1/3 text-left" }, [
        _createElementVNode("span", { class: "text-gray-700 text-sm font-medium leading-5" }, "Name")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BaseInput, {
              id: "firstName",
              modelValue: _ctx.localFirstName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFirstName) = $event)),
              type: "text",
              className: "px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap",
              required: "",
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:firstName', _ctx.localFirstName)))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_BaseInput, {
              id: "lastName",
              modelValue: _ctx.localLastName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localLastName) = $event)),
              type: "text",
              className: "px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap",
              required: "",
              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:lastName', _ctx.localLastName)))
            }, null, 8, ["modelValue"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "flex flex-col w-1/3 text-left" }, [
        _createElementVNode("span", { class: "text-gray-700 text-sm font-medium leading-5" }, "Email Address")
      ], -1)),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_BaseInput, {
          id: "email",
          modelValue: _ctx.localEmailAddress,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localEmailAddress) = $event)),
          type: "text",
          className: "px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap",
          required: "",
          onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:email', _ctx.localEmailAddress)))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex flex-col w-1/3 text-left" }, [
        _createElementVNode("span", { class: "text-gray-700 text-sm font-medium leading-5" }, "Your photo"),
        _createElementVNode("span", { class: "text-gray-500 text-sm font-normal leading-5" }, "Your photo will be displayed on your profile.")
      ], -1)),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          (_ctx.profilePictureUrl)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.profilePictureUrl,
                alt: "User profile picture",
                class: "w-full h-full object-cover",
                loading: "lazy"
              }, null, 8, _hoisted_12))
            : (_openBlock(), _createElementBlock("img", _hoisted_13))
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_BaseButton, {
            type: "button",
            onClick: _ctx.handleChangeClick,
            class: "px-4 py-2 text-xs sm:text-sm font-medium leading-none text-black border border-gray-300 rounded-md bg-transparent"
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode(" Change ")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createElementVNode("input", {
          ref: "fileInput",
          type: "file",
          id: "imageUpload",
          class: "sr-only",
          accept: "image/png,image/jpeg,image/jpg",
          onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleImageUpload && _ctx.handleImageUpload(...args)))
        }, null, 544)
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _cache[17] || (_cache[17] = _createElementVNode("div", { class: "flex flex-col w-1/3 text-left" }, [
        _createElementVNode("span", { class: "text-gray-700 text-sm font-medium leading-5" }, "Title")
      ], -1)),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_BaseInput, {
          id: "title",
          modelValue: _ctx.localTitle,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localTitle) = $event)),
          type: "text",
          className: "px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap",
          required: "",
          onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('update:title', _ctx.localTitle)))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _cache[18] || (_cache[18] = _createElementVNode("div", { class: "flex flex-col w-1/3 text-left" }, [
        _createElementVNode("span", { class: "text-gray-700 text-sm font-medium leading-5" }, "Company Name")
      ], -1)),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_BaseInput, {
          id: "companyName",
          modelValue: _ctx.localCompanyName,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localCompanyName) = $event)),
          type: "text",
          className: "px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap",
          required: "",
          onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('update:companyName', _ctx.localCompanyName)))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "flex flex-col w-1/3 text-left" }, [
        _createElementVNode("span", { class: "text-gray-700 text-sm font-medium leading-5" }, "Company Website")
      ], -1)),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_BaseInput, {
          id: "companyWebsite",
          modelValue: _ctx.localCompanyWebsite,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localCompanyWebsite) = $event)),
          type: "text",
          className: "px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap",
          required: "",
          onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('update:companyWebsite', _ctx.localCompanyWebsite)))
        }, null, 8, ["modelValue"])
      ])
    ])
  ]))
}
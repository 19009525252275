<template>
  <div class="flex flex-col w-4/5 max:md-w-1/2 space-y-4">
    <!-- Name Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-6 border-b border-gray-200"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5">Name</span>
      </div>
      <div class="flex flex-col flex-1 gap-2 text-left">
        <div class="flex gap-2 w-full">
          <div class="flex-1">
            <BaseInput
              id="firstName"
              v-model="localFirstName"
              type="text"
              className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
              required
              @change="$emit('update:firstName', localFirstName)"
            />
          </div>
          <div class="flex-1">
            <BaseInput
              id="lastName"
              v-model="localLastName"
              type="text"
              className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
              required
              @change="$emit('update:lastName', localLastName)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Email Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5"
          >Email Address</span
        >
      </div>
      <div class="flex flex-col flex-1 text-left">
        <BaseInput
          id="email"
          v-model="localEmailAddress"
          type="text"
          className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
          required
          @change="$emit('update:email', localEmailAddress)"
        />
      </div>
    </div>

    <!-- Profile Photo Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5"
          >Your photo</span
        >
        <span class="text-gray-500 text-sm font-normal leading-5"
          >Your photo will be displayed on your profile.</span
        >
      </div>
      <div class="flex flex-wrap flex-1 text-left gap-4 items-center">
        <div
          class="relative w-12 h-12 overflow-hidden rounded-full flex items-center justify-center"
        >
          <img
            v-if="profilePictureUrl"
            :src="profilePictureUrl"
            alt="User profile picture"
            class="w-full h-full object-cover"
            loading="lazy"
          />
          <img
            v-else
            src="@/assets/shared/user.svg"
            alt="Default profile picture"
            class="w-full h-full object-cover"
            loading="lazy"
          />
        </div>
        <div class="flex flex-col">
          <BaseButton
            type="button"
            @click="handleChangeClick"
            class="px-4 py-2 text-xs sm:text-sm font-medium leading-none text-black border border-gray-300 rounded-md bg-transparent"
          >
            Change
          </BaseButton>
        </div>
        <input
          ref="fileInput"
          type="file"
          id="imageUpload"
          class="sr-only"
          accept="image/png,image/jpeg,image/jpg"
          @change="handleImageUpload"
        />
      </div>
    </div>

    <!-- Title Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5">Title</span>
      </div>
      <div class="flex flex-col flex-1 text-left">
        <BaseInput
          id="title"
          v-model="localTitle"
          type="text"
          className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
          required
          @change="$emit('update:title', localTitle)"
        />
      </div>
    </div>

    <!-- Company Name Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5"
          >Company Name</span
        >
      </div>
      <div class="flex flex-col flex-1 text-left">
        <BaseInput
          id="companyName"
          v-model="localCompanyName"
          type="text"
          className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
          required
          @change="$emit('update:companyName', localCompanyName)"
        />
      </div>
    </div>

    <!-- Company Website Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5"
          >Company Website</span
        >
      </div>
      <div class="flex flex-col flex-1 text-left">
        <BaseInput
          id="companyWebsite"
          v-model="localCompanyWebsite"
          type="text"
          className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
          required
          @change="$emit('update:companyWebsite', localCompanyWebsite)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useUserStore } from "@/stores/user";
import BaseInput from "@/components/shared/BaseInput.vue";
import BaseButton from "@/components/shared/BaseButton.vue";

export default defineComponent({
  name: "CurrentDetails",
  components: {
    BaseInput,
    BaseButton,
  },
  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    profilePicture: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
    companyWebsite: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const localProfilePicture = ref(props.profilePicture);
    const localFirstName = ref(props.firstName);
    const localLastName = ref(props.lastName);
    const localEmailAddress = ref(props.email);
    const localTitle = ref(props.title);
    const localCompanyName = ref(props.companyName);
    const localCompanyWebsite = ref(props.companyWebsite);
    const errorMessage = ref("");
    const imageSrc = ref(props.profilePicture);
    const fileInput = ref<HTMLInputElement | null>(null);
    const userStore = useUserStore();

    const handleChangeClick = () => {
      console.log("handleChangeClick", fileInput.value);
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const handleImageUpload = (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const file = input.files[0];
        const { type, size } = file;

        const ALLOWED_MIME_TYPES = ["image/jpeg", "image/png", "image/jpg"];
        const MAX_SIZE_MB = 5;

        errorMessage.value = "";

        if (!ALLOWED_MIME_TYPES.includes(type)) {
          errorMessage.value = "Only JPG, JPEG, and PNG files are allowed.";
          return;
        }

        if (size > MAX_SIZE_MB * 1024 * 1024) {
          errorMessage.value = "File size exceeds 5MB.";
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          imageSrc.value = reader.result as string;
          emit("update:profilePicture", getCleanBase64(imageSrc.value));
        };
        reader.readAsDataURL(file);

        input.value = "";
      }
    };

    const getCleanBase64 = (base64String: string) => {
      return base64String.replace(/^data:image\/(jpeg|jpg|png);base64,/, "");
    };

    const profilePictureUrl = computed(() => {
      if (imageSrc.value.startsWith("https://")) {
        return `${imageSrc.value}?t=${new Date().getTime()}`;
      } else {
        return imageSrc.value;
      }
    });

    return {
      localProfilePicture,
      localFirstName,
      localLastName,
      localEmailAddress,
      localTitle,
      localCompanyName,
      localCompanyWebsite,
      userStore,
      handleChangeClick,
      handleImageUpload,
      errorMessage,
      imageSrc,
      fileInput,
      profilePictureUrl,
      // // handleFirstNameChange,
      // handleLastNameChange,
      // handleEmailChange,
      // handleTitleChange,
      // handleCompanyNameChange,
      // handleCompanyWebsiteChange,
    };
  },
});
</script>

<template>
  <div
    class="flex flex-col gap-4 justify-between p-4 w-full lg:w-[40vw] max-w-[500px]"
  >
    <div class="flex flex-col justify-center items-center">
      <span class="text-gray-900 font-sans text-2xl font-bold leading-7"
        >Meeting confirmed</span
      >
    </div>
    <div class="flex flex-col gap-6">
      <div class="flex flex-wrap gap-2">
        <div v-html="CLOCK_ICON"></div>
        <span class="text-gray-900/60 font-sans text-base font-bold leading-6"
          >30 min</span
        >
      </div>
      <div class="flex flex-wrap gap-2">
        <div v-html="PHONE_ICON"></div>
        <span class="text-gray-900/60 font-sans text-base font-bold leading-6"
          >Phone call</span
        >
      </div>
      <div class="flex flex-row gap-2 text-left">
        <div v-html="CALENDAR_ICON"></div>
        <span
          class="text-gray-900/60 font-sans text-base font-bold leading-6"
          >{{ dateAndTime }}</span
        >
      </div>
      <div class="flex wrap gap-2">
        <div v-html="GLOBE_ICON" class="w-4 h-4 text-gray-900"></div>
        <span
          class="text-gray-900 text-center font-inter text-sm font-normal leading-5"
          >{{ timeZone }}</span
        >
      </div>
      <div class="flex flex-col gap-2 text-left">
        <span
          class="text-primary-purple max-w-max bg-indigo-50 px-4 py-1 rounded-md font-sans text-base font-bold leading-6"
          >Meeting Passcode - {{ meetingCode }}</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { getCurrentTimeZoneInfo } from "@/utils/dateAndTimeUtils";
import {
  CLOCK_ICON,
  PHONE_ICON,
  CALENDAR_ICON,
  GLOBE_ICON,
} from "@/assets/svg/shared/svg-constants";

export default defineComponent({
  name: "BookedMeetingDetails",
  setup() {
    const route = useRoute();
    const meetingCode = ref(route.query.meeting_code);
    const timeZoneInfo = getCurrentTimeZoneInfo();
    const timeZone = timeZoneInfo.timeZone;

    const formatDate = (dateString: string): string => {
      const date = new Date(dateString);

      // Define options for the date format
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long", // Full weekday name (e.g., "Monday")
        month: "short", // Short month name (e.g., "Nov")
        day: "numeric", // Day of the month (e.g., "18")
        year: "numeric", // Full year (e.g., "2024")
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    };

    const formatTimeRange = (timeString: string): string => {
      // Create a Date object for the starting time (8:00 PM)
      const [hour, minute] = timeString.split(":").map(Number);
      const startTime = new Date();
      startTime.setHours(hour, minute, 0, 0);

      // Calculate the ending time (8:30 PM)
      const endTime = new Date(startTime.getTime() + 30 * 60000); // Add 30 minutes

      // Format both times
      const options: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };

      const startFormatted = new Intl.DateTimeFormat("en-US", options).format(
        startTime
      );
      const endFormatted = new Intl.DateTimeFormat("en-US", options).format(
        endTime
      );

      return `${startFormatted} - ${endFormatted}`;
    };

    const dateAndTime = computed(() => {
      const date = route.query.date as string;
      const time = route.query.startTime as string;
      return `${formatTimeRange(time)}, ${formatDate(date)} `;
    });

    return {
      meetingCode,
      PHONE_ICON,
      CLOCK_ICON,
      CALENDAR_ICON,
      GLOBE_ICON,
      dateAndTime,
      timeZone,
    };
  },
});
</script>

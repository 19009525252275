import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 w-full" }
const _hoisted_2 = { class: "flex flex-col overflow-x-auto" }
const _hoisted_3 = { class: "min-w-full table-auto border border-gray-300 mb-8" }
const _hoisted_4 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "truncate" }
const _hoisted_7 = { class: "truncate" }
const _hoisted_8 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = { class: "truncate" }
const _hoisted_11 = { class: "truncate" }
const _hoisted_12 = { class: "px-4 py-2 font-medium text-xs leading-4 border-b text-gray-900" }
const _hoisted_13 = { class: "truncate" }
const _hoisted_14 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_15 = { class: "flex flex-col" }
const _hoisted_16 = { class: "truncate" }
const _hoisted_17 = {
  key: 0,
  class: "truncate"
}
const _hoisted_18 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_19 = { class: "relative group" }
const _hoisted_20 = { class: "absolute invisible whitespace-nowrap opacity-0 group-hover:visible group-hover:opacity-100 transition bg-gray-800 text-white text-xs rounded px-2 py-1 left-1/2 transform -translate-x-1/2 bottom-full mb-1 z-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col text-left" }, [
      _createElementVNode("span", { class: "text-gray-900 text-left font-sans text-lg font-semibold leading-9" }, "Expert Meetings")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
          _createElementVNode("tr", { class: "border-b border-gray-300" }, [
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Expert "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Attendee "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Meeting Date "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Status "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Payout "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Deal / Opportunity Amount ")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookings, (row, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: index,
              class: "hover:bg-gray-50 text-left"
            }, [
              _createElementVNode("td", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(row?.user?.firstName + " " + row?.user?.lastName), 1),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(row?.user?.email), 1)
                ])
              ]),
              _createElementVNode("td", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(row?.attendee?.name), 1),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(row?.attendee?.email), 1)
                ])
              ]),
              _createElementVNode("td", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.convertToDate(row.startTime)), 1)
              ]),
              _createElementVNode("td", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(row?.status.toUpperCase()), 1),
                  (row.status === 'incomplete')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, "(Under 5 mins, no payout)"))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("td", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createTextVNode(" $" + _toDisplayString(_ctx.bookingPayout(row?.programPayout, row?.status)) + " ", 1),
                  _createElementVNode("div", _hoisted_20, " Fee: $" + _toDisplayString(_ctx.fees(row?.programPayout, row?.fees, row?.status)), 1)
                ])
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("td", { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }, [
                _createElementVNode("div", { class: "truncate" }, "Coming Soon")
              ], -1))
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../../../assets/shared/failure.svg'
import _imports_1 from '../../../../assets/expert-program/directory/traffic-lights.svg'
import _imports_2 from '../../../../assets/expert-program/directory/browser-actions.svg'


const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_3 = { class: "flex flex-wrap justify-between p-4" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_5 = { class: "flex flex-wrap gap-6" }
const _hoisted_6 = { class: "flex flex-wrap gap-2" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_9 = { class: "flex flex-wrap gap-2 items-center" }
const _hoisted_10 = {
  key: 0,
  class: "text-primary-purple"
}
const _hoisted_11 = { class: "flex justify-center mb-4" }
const _hoisted_12 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_13 = { class: "flex gap-5 max-md:flex-col" }
const _hoisted_14 = {
  id: "unevenGridContainer",
  class: "flex flex-col w-full mx-auto w-full bg-white max-md:mt-8 max-md:max-w-full"
}
const _hoisted_15 = {
  id: "directoryPreview",
  class: "flex flex-wrap items-center pt-2 pr-2 pl-3.5 text-xs whitespace-nowrap text-zinc-700 max-md:pr-5"
}
const _hoisted_16 = { class: "flex flex-col bg-gray-200 w-full rounded-t-lg px-2" }
const _hoisted_17 = { class: "flex flex-row justify-start gap-2 ml-2 pt-2 pt-3" }
const _hoisted_18 = { class: "flex flex-row bg-white rounded-t-2xl w-full justify-between max-w-xs p-1" }
const _hoisted_19 = { class: "flex flex-row justify-start items-center" }
const _hoisted_20 = { class: "ml-2" }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "flex flex-row justify-end items-center mr-3" }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = { class: "flex flex-row items-center ml-2" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = { class: "flex flex-col w-full px-2" }
const _hoisted_27 = { class: "flex flex-row justify-start items-center gap-2 ml-2 py-2" }
const _hoisted_28 = { class: "flex flex-row bg-gray-100 w-full p-1 rounded-full items-center whitespace-nowrap overflow-ellipsis overflow-hidden" }
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { class: "ml-2 overflow-hidden" }
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = {
  key: 0,
  class: "flex gap-2 bg-gray-100 p-6 justify-center w-full max-md:max-w-full"
}
const _hoisted_33 = { key: 1 }
const _hoisted_34 = {
  key: 0,
  class: "flex flex-col ml-5 w-[37%] max-md:ml-0 max-md:w-full"
}
const _hoisted_35 = { class: "flex flex-col max-md:mt-8" }
const _hoisted_36 = { class: "flex-flex-col" }
const _hoisted_37 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ProfileCard = _resolveComponent("ProfileCard")!
  const _component_TemplateSelector = _resolveComponent("TemplateSelector")!
  const _component_CardStyling = _resolveComponent("CardStyling")!
  const _component_DirectoryCodeModal = _resolveComponent("DirectoryCodeModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex flex-col text-left" }, [
          _createElementVNode("span", { class: "text-gray-900 font-inter text-base font-medium leading-6" }, "Directory"),
          _createElementVNode("span", { class: "text-gray-500 font-inter text-sm font-normal leading-8" }, "You can update the directory template")
        ], -1)),
        (!_ctx.showEditControls)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_BaseButton, {
                  type: "button",
                  class: "gap-2 text-primary-purple font-inter text-base font-medium leading-6",
                  onClick: _ctx.showCodeModal
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Setup/Install Code ")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_BaseButton, {
                  type: "button",
                  class: "gap-2 text-primary-purple font-inter text-base font-medium leading-6",
                  onClick: _ctx.handleEditDirectory
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", { innerHTML: _ctx.EDIT_ICON }, null, 8, _hoisted_7),
                      _cache[3] || (_cache[3] = _createTextVNode(" Edit "))
                    ])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                (_ctx.updateDirectoryLoading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Updating..."))
                  : _createCommentVNode("", true),
                _createVNode(_component_BaseButton, {
                  type: "button",
                  class: "text-black border p-2 rounded-lg border-gray-300 font-inter text-base font-medium leading-6",
                  onClick: _ctx.hideEditControls
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Cancel ")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_BaseButton, {
                  type: "button",
                  class: "bg-primary-purple text-white p-2 rounded-lg font-inter text-base font-medium leading-6",
                  onClick: _ctx.handleUpdateDirectory
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Update ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Error Icon",
                class: "w-4 h-4"
              })
            ], -1)),
            _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("section", {
        class: _normalizeClass(["flex flex-col max-md:ml-0 max-md:w-full", { 'w-full': !_ctx.showEditControls, 'w-[63%]': _ctx.showEditControls }])
      }, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _cache[8] || (_cache[8] = _createElementVNode("img", {
                  loading: "lazy",
                  src: _imports_1,
                  class: "object-fit shrink-0 self-stretch my-auto",
                  alt: ""
                }, null, -1)),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("img", {
                        loading: "lazy",
                        src: _ctx.logoFromWebsite(_ctx.userStore.companyWebsite || ''),
                        class: "w-8 h-8 mr-2 rounded-full"
                      }, null, 8, _hoisted_21)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.userStore.companyName), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", { innerHTML: _ctx.CLOSE_ICON_SM }, null, 8, _hoisted_23)
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.PLUS_ICON,
                    class: "text-gray-600"
                  }, null, 8, _hoisted_25)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, [
                _cache[9] || (_cache[9] = _createElementVNode("img", {
                  loading: "lazy",
                  src: _imports_2,
                  class: "object-fit shrink-0 self-stretch my-auto",
                  alt: ""
                }, null, -1)),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.LOCK_ICON,
                    class: "text-gray-600 ml-2"
                  }, null, 8, _hoisted_29),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.userStore.companyWebsite) + "/experts", 1)
                  ])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.BROWSER_MENU_ICON,
                    class: "text-gray-600 ml-2"
                  }, null, 8, _hoisted_31)
                ])
              ])
            ]),
            (_ctx.isGridUneven)
              ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (n) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: n,
                      class: "space-y-3"
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profiles.filter(
                  (_, index) => index % _ctx.columns === n - 1
                ), (profile) => {
                        return (_openBlock(), _createBlock(_component_ProfileCard, {
                          key: profile.id,
                          profile: profile,
                          isCentered: _ctx.profileCenterAligned,
                          isGridUneven: _ctx.isGridUneven,
                          profileImageClass: _ctx.profileImageClass,
                          profileImageAndNameLayoutClass: _ctx.profileImageAndNameLayoutClass,
                          fontFamily: _ctx.fontFamily,
                          boldness: _ctx.boldness,
                          textColor: _ctx.textColor,
                          backgroundColor: _ctx.backgroundColor,
                          fontSize: _ctx.fontSize,
                          class: "flex-shrink-0"
                        }, null, 8, ["profile", "isCentered", "isGridUneven", "profileImageClass", "profileImageAndNameLayoutClass", "fontFamily", "boldness", "textColor", "backgroundColor", "fontSize"]))
                      }), 128))
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_33, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["flex flex-wrap bg-gray-100 p-6 justify-center w-full max-md:max-w-full", _ctx.profileCardsGap])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profiles, (profile) => {
                      return (_openBlock(), _createBlock(_component_ProfileCard, {
                        key: profile.id,
                        profile: profile,
                        isCentered: _ctx.profileCenterAligned,
                        profileImageClass: _ctx.profileImageClass,
                        isGridUneven: _ctx.isGridUneven,
                        profileImageAndNameLayoutClass: _ctx.profileImageAndNameLayoutClass,
                        fontFamily: _ctx.fontFamily,
                        boldness: _ctx.boldness,
                        textColor: _ctx.textColor,
                        backgroundColor: _ctx.backgroundColor,
                        fontSize: _ctx.fontSize,
                        class: "flex-shrink-0"
                      }, null, 8, ["profile", "isCentered", "profileImageClass", "isGridUneven", "profileImageAndNameLayoutClass", "fontFamily", "boldness", "textColor", "backgroundColor", "fontSize"]))
                    }), 128))
                  ], 2)
                ]))
          ])
        ])
      ], 2),
      (_ctx.showEditControls)
        ? (_openBlock(), _createElementBlock("section", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, [
                _createVNode(_component_TemplateSelector, {
                  initialSelected: _ctx.selectedTemplate,
                  onTemplateSelected: _ctx.applyCardTemplate
                }, null, 8, ["initialSelected", "onTemplateSelected"])
              ]),
              _createElementVNode("div", _hoisted_37, [
                _createVNode(_component_CardStyling, {
                  fontFamily: _ctx.fontFamily,
                  fontSize: _ctx.fontSize,
                  buttonTextColor: _ctx.textColor,
                  boldness: _ctx.boldness,
                  buttonBackgroundColor: _ctx.backgroundColor,
                  onFontFamilyChanged: _ctx.handleFontChange,
                  onBoldnessChanged: _ctx.handleBoldnessChange,
                  onButtonTextColorChanged: _ctx.handleTextColorChange,
                  onButtonBackgroundColorChanged: _ctx.handleBackgroundColorChange,
                  onFontSizeChanged: _ctx.handleFontSizeChange
                }, null, 8, ["fontFamily", "fontSize", "buttonTextColor", "boldness", "buttonBackgroundColor", "onFontFamilyChanged", "onBoldnessChanged", "onButtonTextColorChanged", "onButtonBackgroundColorChanged", "onFontSizeChanged"])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_component_DirectoryCodeModal, {
          isVisible: _ctx.isCodeModalVisible,
          companyId: _ctx.companyId,
          programUuid: _ctx.programUuid,
          onCloseCodeModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCodeModalVisible = false))
        }, null, 8, ["isVisible", "companyId", "programUuid"])
      ])
    ])
  ], 64))
}
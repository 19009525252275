<template>
  <div class="overflow-x-auto w-full pr-4">
    <div class="flex justify-center mb-4">
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
        style="max-height: 80px; overflow-y: auto"
      >
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <img
            src="../../../../assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-wrap justify-between items-center mb-4 gap-2">
        <div class="flex flex-wrap gap-2">
          <BaseButton
            type="button"
            @click="setActiveTab(0)"
            class="flex justify-center items-center py-2 px-8 rounded-full text-sm font-medium"
            :class="
              activeTab === 0
                ? 'bg-primary-purple text-white'
                : 'bg-indigo-100 text-primary-purple'
            "
          >
            Expert Invites
          </BaseButton>
          <BaseButton
            type="button"
            @click="setActiveTab(1)"
            class="flex justify-center items-center py-2 px-8 rounded-full text-sm font-medium"
            :class="
              activeTab === 1
                ? 'bg-primary-purple text-white'
                : 'bg-indigo-100 text-primary-purple'
            "
          >
            Requests
          </BaseButton>
          <BaseButton
            type="button"
            @click="setActiveTab(2)"
            class="flex justify-center items-center py-2 px-8 rounded-full text-sm font-medium"
            :class="
              activeTab === 2
                ? 'bg-primary-purple text-white'
                : 'bg-indigo-100 text-primary-purple'
            "
          >
            Suggested Experts
          </BaseButton>
        </div>
        <div class="flex flex-col" v-if="activeTab === 0">
          <BaseButton
            type="button"
            class="flex justify-center items-center py-2 px-8 rounded-md text-sm font-medium bg-indigo-100"
            style="width: auto"
            @click="isAddMemberModalOpen = true"
          >
            <div class="flex items-center text-primary-purple">
              <span>Invite Expert</span>
            </div>
          </BaseButton>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <ExpertsList
        ref="expertsListRef"
        v-if="activeTab === 0"
        :handleError="handleError"
      />
      <RequestsList
        v-if="activeTab === 1"
        :handleError="handleError"
        :openProfileUuid="selectProfileUuid"
      />
      <SuggestedExperts
        v-if="activeTab === 2"
        :programId="userStore.selectedProgram?.id"
        :handleError="handleError"
      />
    </div>
  </div>
  <AddTeamMemberModal
    v-if="isAddMemberModalOpen"
    invitationType="experts"
    :isVisible="isAddMemberModalOpen"
    @update:isVisible="closeInvitationModal"
    @update:emailsSent="handleEmailsSent"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { DELETE_ICON } from "@/assets/svg/shared/svg-constants";
import BaseButton from "@/components/shared/BaseButton.vue";
import AddTeamMemberModal from "@/components/team/AddTeamMemberModal.vue";
import { Expert, Invitation } from "@/types/expert-programs-types";
import { DOTS_VERTICAL_ICON } from "@/assets/svg/shared/svg-constants";
import ExpertsList from "./ExpertsList.vue";
import RequestsList from "./RequestsList.vue";
import SuggestedExperts from "@/components/experts/SuggestedExperts.vue";
import { useUserStore } from "@/stores/user";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "PageContainer",
  components: {
    BaseButton,
    AddTeamMemberModal,
    ExpertsList,
    RequestsList,
    SuggestedExperts,
  },
  setup() {
    const errorMessage = ref("");
    const isAddMemberModalOpen = ref(false);
    const expertsRows = ref<Expert[]>([]);
    const invitationsRows = ref<Invitation[]>([]);
    const isDropdownOpen = ref<string | null>(null);
    const activeTab = ref(0);
    const userStore = useUserStore();
    const selectProfileUuid = ref("");
    const expertsListRef = ref<InstanceType<typeof ExpertsList> | null>(null);
    const route = useRoute();
    const router = useRouter();

    const handleEmailsSent = () => {
      isAddMemberModalOpen.value = false;
      expertsListRef.value?.refreshExperts();
    };

    const handleError = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 3000);
    };

    const setActiveTab = (tab: number) => {
      activeTab.value = tab;
      router.push({
        query: {
          tab: "2",
          subtab: tab.toString(),
        },
      });
    };

    const closeInvitationModal = () => {
      isAddMemberModalOpen.value = false;
      expertsListRef.value?.refreshExperts();
    };

    onMounted(() => {
      if (route.query.user) {
        selectProfileUuid.value = route.query.user as string;
      }
      if (!route.query.subtab) {
        setActiveTab(0);
      } else {
        setActiveTab(Number(route.query.subtab));
      }
    });

    return {
      DELETE_ICON,
      errorMessage,
      expertsRows,
      invitationsRows,
      isAddMemberModalOpen,
      handleEmailsSent,
      DOTS_VERTICAL_ICON,
      isDropdownOpen,
      activeTab,
      closeInvitationModal,
      expertsListRef,
      handleError,
      userStore,
      selectProfileUuid,
      setActiveTab,
    };
  },
});
</script>

<style>
/* Spinner rotation animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Spinner color animation */
@keyframes color-change {
  0% {
    border-top-color: #8b5cf6; /* Violet color */
  }
  50% {
    border-top-color: #fff; /* White color */
  }
  100% {
    border-top-color: #8b5cf6; /* Violet color */
  }
}

/* Apply animations */
.spinner {
  animation: spin 1s linear infinite, color-change 1s linear infinite;
}
</style>
